import React, {CSSProperties, FC} from 'react';
import FadeLoader from "react-spinners/FadeLoader";
import './Spinner.scss'

interface ISpinner {
    isLoading: boolean;
}

const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
};

const Spinner: FC<ISpinner> = ({isLoading}) => {

    return (
        <div className="spinner">
            <FadeLoader
                color={'#1e96fc'}
                loading={isLoading}
                cssOverride={override}
                aria-label="Loading Spinner"
            />
        </div>
    );
};

export default Spinner;
