import React, {FC} from 'react';
import {Controller} from "react-hook-form";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import FormControl from '@mui/material/FormControl';
import {FormHelperText, Input as MUIInput} from "@mui/material";
import './Textarea.scss'

interface Props {
    name?: string;
    title: string;
    placeholder: string;
    control?: any;
    rules?: Record<string, unknown>;
}

const Textarea: FC<Props> = ({
     name,
     title,
     placeholder,
     control,
     rules = {},
 }) => {
    return (
        <div>
            <Controller
                name={name || ''}
                control={control}
                rules={rules}
                render={({field: {onChange, value}, fieldState: {error}}) =>
                    <FormControl className="textareaField">
                        <div className="textareaField__title">{title}</div>
                        <TextareaAutosize
                            className={`textareaField__textarea ${error ? 'error' : ''}`}
                            maxRows={4}
                            placeholder={placeholder}
                            value={value}
                            onChange={onChange}
                        />
                        {!!error && (
                            <FormHelperText error id={name}>
                                {`${error.message}`}
                            </FormHelperText>
                        )}
                    </FormControl>
                }
            />
        </div>
    );
};

export default Textarea;
