import React, {FC, useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import moment from "moment";
import Button from '@mui/material/Button';
import {useAppDispatch, useAppSelector} from '../../../hooks/global';
import {fetchAffiliatesRequests, setFilterParams, fetchAffiliateRequestsReport} from "../../../store/affiliate/affiliateSlice";
import HeaderView from '../../common/Header/HeaderView'
import FooterView from '../../common/Footer/FooterView'
import TopBanner from '../../common/TopBanner/TopBanner'
import DataTableView from "../../common/DataTable/DataTableView";
import {Select} from "../../controls";
import Spinner from "../../common/Spinner/Spinner";
import './AffiliateRequests.scss'

const columns = [
    {
        name: 'Affiliate',
        alias: 'affiliate_title',
        selector: (row: any) => row.affiliate_title,
        sortable: false,
        width: '150px',
    },
    {
        name: 'Customer First Name',
        alias: 'first_name',
        selector: (row: any) => row?.customer?.profile.first_name,
        sortable: false,
        width: '160px',
    },
    {
        name: 'Customer Last Name',
        alias: 'last_name',
        selector: (row: any) => row?.customer?.profile.last_name,
        sortable: false,
        width: '160px',
    },
    {
        name: `Guru Code`,
        alias: 'code',
        selector: (row: any) => row?.customer?.profile.code,
        sortable: false,
        width: '115px',
    },
    {
        name: 'Customer Phone Number',
        alias: 'phone',
        selector: (row: any) => row?.customer?.address.phone,
        sortable: false,
        width: '160px',
    },
    {
        name: 'Customer Email Address',
        alias: 'email',
        selector: (row: any) => row?.customer?.email,
        sortable: false,
    },
    {
        name: 'Date Contacted',
        alias: 'created_at',
        selector: (row: any) => moment(row.created_at).format('MM/DD/YYYY hh:mm A'),
        sortable: false,
        width: '180px',
    },
];

const affiliatesOptions = [
    {value: '', label: 'All Affiliates'},
    {value: 'dfg', label: 'Drug-Free Solutions Group'},
    {value: 'current', label: 'Current'},
    {value: 'ora', label: 'OraSure'},
    {value: 'quantum', label: 'Quantum'},
    {value: 'currency', label: 'Currency'},
]

const getYears = () => {
    const now = Number(moment().format('YYYY'));
    const years = [];
    for (let i = 0; i < 10; i++) {
        years.push({value: (now-i).toString(), label: (now-i).toString()});
    }
    return years;
}

const AffiliateRequests: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const didMountRef = useRef(false);
    const {requests, listFilters, pagination,  isLoading} = useAppSelector(state => state.affiliate);
    const keepFilters = location.state?.keepFilters || false;
    const {control, handleSubmit, reset, getValues } = useForm({
        mode: 'onBlur',
        defaultValues: {
            affiliate: listFilters.affiliate,
            year: listFilters.year,
        }
    });

    useEffect(() => {
        didMountRef.current = true;
        if (!keepFilters) handleReset();
    }, []);

    useEffect(() => {
        if (didMountRef.current) {
            dispatch(fetchAffiliatesRequests(listFilters));
        }
    }, [listFilters]);

    const handleSearch = (data: any) => {
        dispatch(setFilterParams({...listFilters, ...data, page: 1}));
    };

    const handleReset = () => {
        const data = {
            affiliate: '',
            year: moment().format('YYYY'),
            order_by: 'created_at',
            order_dir: 'desc',
        };
        reset(data);
        dispatch(setFilterParams({...listFilters, ...data, page: 1}));
    };

    const handleChangePage = (page: number) => {
        dispatch(setFilterParams({...listFilters, page}));
    };

    const handleSort = (order_by: string, order_dir: string) => {
        dispatch(setFilterParams({...listFilters, order_by, order_dir, page: 1}));
    };

    const handleReportExport = () => {
        dispatch(fetchAffiliateRequestsReport({
            affiliate: getValues('affiliate'),
                year: getValues('year')
            }));
    }

    return (
        <div className="contentWrapper">
            <HeaderView/>
            <Spinner isLoading={isLoading}/>
            <div className="pageContainer">
                <TopBanner/>
                <div className="pageContainer__view">
                    <div className="pageHeader">
                        <div className="pageHeader__rowTitle">
                            <h2 className="pageHeader__title">Affiliate Referral Report</h2>
                        </div>
                    </div>
                    <form className="formContainer">
                        <div className="formContainer__row -grid-4">
                            <Select
                                control={control}
                                name={'affiliate'}
                                title={'Affiliate Name'}
                                options={affiliatesOptions}
                            />
                            <Select
                                control={control}
                                name={'year'}
                                title={'Year'}
                                options={getYears()}
                            />
                            <div/>
                            <Button
                                onClick={handleReportExport}
                                variant="contained"
                                className="pageReportInfo__btn simpleBtn -border exportBtn">
                                Export to Excel
                            </Button>
                        </div>

                        <Button
                            onClick={handleSubmit(handleSearch)}
                            type={"submit"}
                            variant="contained"
                            className="simpleBtn -blue">
                            search
                        </Button>
                        <Button
                            onClick={handleReset}
                            variant="contained"
                            className="simpleBtn -ml-10">
                            Reset
                        </Button>
                    </form>
                    <div className="tableList -customer">
                        <h3 className="tableList__title">Search Results</h3>
                        <DataTableView
                            data={requests}
                            columns={columns}
                            currentPage={pagination.current_page}
                            paginationTotalRows={pagination.total}
                            paginationPerPage={pagination.per_page}
                            lastPage={pagination.last_page}
                            from={pagination.from}
                            to={pagination.to}
                            onChangePage={(page) => handleChangePage(page)}
                            onSort={handleSort}
                        />
                    </div>
                </div>
            </div>
            <FooterView/>
        </div>
    );
};

export default AffiliateRequests;
