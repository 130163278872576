import React, {FC, Ref, useRef, useState} from 'react';
import { Controller } from 'react-hook-form';
import { Editor } from '@tinymce/tinymce-react';
import './TinyMceEditor.scss'

interface Props {
    name?: string;
    title?: string;
    value?: string;
    placeholder?: string;
    editorRef?: React.MutableRefObject<null>;
    onChange?: any;
    height?: number;
}

const TinyMceEditor: FC<Props> = ({
        name,
        title,
        value,
        placeholder,
        editorRef,
        onChange,
        height = 400,
    }) => {

    let ref = useRef(null);
    if (editorRef) ref = editorRef;

    return (
        <Editor
            onInit={(evt, editor: any) => ref.current = editor}
            initialValue={value}
            {...(onChange && {onChange})}
            init={{
                height,
                selector: "textarea",
                browser_spellcheck: true,
                toolbar: 'preview | undo redo | fontfamily styles fontsize bold italic underline lineheight | backcolor forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent pagebreak removeformat',
                plugins: ['advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview','anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'pagebreak'],
                content_style: "@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap'); @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');",
                font_family_formats: "Open Sans=Open Sans; Poppins=Poppins; Century Gothic=Century Gothic;",
                pagebreak_split_block: true,
            }}
        />
    );
};

export default TinyMceEditor;
