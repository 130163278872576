import React, {FC, useEffect, useState} from 'react';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import {useForm} from "react-hook-form";
import moment from 'moment';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import {useAppDispatch, useAppSelector} from "../../../hooks/global";
import {fetchCustomer} from "../../../store/customer/customerSlice";
import {DraftTextarea} from "../../controls";
import HeaderView from '../../common/Header/HeaderView';
import FooterView from '../../common/Footer/FooterView';
import TopBanner from '../../common/TopBanner/TopBanner';
import DataTableView from "../../common/DataTable/DataTableView";
import Spinner from "../../common/Spinner/Spinner";
import './CustomersHistory.scss';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const columns = [
  {
    name: 'Purchase Date',
    alias: 'created_at',
    selector: (row: any) => row.created_at && moment(row.created_at).format('MM/DD/YYYY hh:mm A'),
    sortable: false,
    width: '340px',
  },
  {
    name: 'Items Purchased',
    alias: 'package',
    selector: (row: any) => row.package_name,
    sortable: false,
    width: '440px',
  },
  {
    name: 'Total Cost',
    alias: 'price',
    selector: (row: any) => formatter.format(row.price),
    sortable: false,
  },
];

const CustomersHistory: FC = () => {
  const {id} = useParams();
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const {customer, orders, isLoading} = useAppSelector(state => state.customer);
  const [notesOpened, setNotesOpened] = useState(false);
  const [notes2Opened, setNotes2Opened] = useState(false);

  useEffect(() => {
    if (id) dispatch(fetchCustomer(id));
  }, [id]);

  const getCustomerName = (customer: any): string => {
    return `${customer?.profile.first_name || ''} ${customer?.profile.last_name || ''}`
  }

  const {control} = useForm({
    mode: 'onBlur',
    defaultValues: {
      warning_notes: '',
    }
  });

  return (
    <div className="contentWrapper">
      <HeaderView />
      <Spinner isLoading={isLoading}/>
      <div className="pageContainer">
        <TopBanner />
        <div className="pageContainer__view">
          <div className="pageHeader">
            <div className="pageHeader__rowTitle">
              <ul className="pageHeader__tabList">
                <li className="pageHeader__tabItem">
                  <Link
                    underline="hover"
                    component={RouterLink}
                    className="pageHeader__tabLink"
                    to={`/customer/${id}`}
                  >
                    Customer Details
                  </Link>
                </li>
                <li className="pageHeader__tabItem state_active">
                  <Link
                    underline="hover"
                    component={RouterLink}
                    className="pageHeader__tabLink"
                    to={''}
                  >
                    Purchase History
                  </Link>
                </li>
              </ul>
              <Button
                onClick={() => navigation('/customers', {state: {keepFilters: true}})}
                variant="contained"
                className="pageHeader__btn -desktop"
              >
                <span className="pageHeader__btnIcon -back"/>
                Back
              </Button>
            </div>
            <br/>
            <div className="titleAndBtnContainer">
              <div className="pageHeader__rowSubTitle">{getCustomerName(customer)}</div>
              <div className="customerNotesBtnWrapper">
                <Button
                  onClick={() => setNotesOpened(!notesOpened)}
                  disabled={!customer?.profile.warning_notes}
                  variant="contained"
                  className="pageHeader__btn -desktop customerNotesBtn"
                >
                  <span
                    className={`pageHeader__btnIcon ${
                      notesOpened ? '-double-arrow-down' : (!customer?.profile.warning_notes ? '' : '-double-arrow')
                    }`}
                  />
                  Customer Warning Notes
                </Button>
                {notesOpened && (
                  <div className="notesContainer">
                    <DraftTextarea
                        control={control}
                        name={'warning_notes'}
                        defaultValue={customer?.profile.warning_notes || ''}
                        readOnly={true}
                    />
                  </div>
                )}
              </div>
            </div>
            <Button
              onClick={() => navigation('/customers', {state: {keepFilters: true}})}
              variant="contained"
              className="pageHeader__btn -mobile"
            >
              <span className="pageHeader__btnIcon -back"/>
              Back
            </Button>
            <div>
              <Button
                  onClick={() => setNotes2Opened(!notes2Opened)}
                  variant="contained"
                  className="pageHeader__btn -mobile customerNotesBtn"
              >
                  <span
                      className={`pageHeader__btnIcon ${
                          notesOpened ? '-double-arrow-down' : '-double-arrow'
                      }`}
                  />
                Customer Warning Notes
              </Button>
              {notes2Opened && (
                  <div className="notesContainer">
                    <DraftTextarea
                        control={control}
                        name={'warning_notes'}
                        defaultValue={customer?.profile.warning_notes || ''}
                        readOnly={true}
                    />
                  </div>
              )}
            </div>

          </div>
          <div className="tableList -customer">
            <DataTableView
                data={orders}
                columns={columns}
                pagination={false}
            />
          </div>
        </div>
      </div>

      <FooterView />
    </div>
  );
};

export default CustomersHistory;
