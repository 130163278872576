import {createSlice, createAsyncThunk, Action} from '@reduxjs/toolkit';
import api from '../../service/api';
import {SigninModel} from '../../models/user/user.model';

export const fetchSignIn = createAsyncThunk('auth/login', async (params: SigninModel, {rejectWithValue, dispatch}) => {
    const response = await api.post('admin/login', params);
    return response.data?.resource;
});

export const fetchUser = createAsyncThunk('auth/user', async () => {
    const response = await api.get('admin/user');
    return response.data;
});

export const fetchLogout = createAsyncThunk('auth/logout', async () => {
    const response = await api.get('general/logout');
    return response.data?.resource;
});

export const fetchForgotPassword = createAsyncThunk('auth/forgot_password', async (params: any, {
    rejectWithValue,
    dispatch
}) => {
    const response = await api.post('general/password/recovery', params);
    return response.data?.resource;
});

export const fetchSetPassword = createAsyncThunk('auth/set_password', async (params: any, {
    rejectWithValue,
    dispatch
}) => {
    const response = await api.post('general/password/restore', params);
    return response.data?.resource;
});

interface AuthState {
    accessToken: string | null;
    refreshToken: string | null;
    user: Record<string, unknown> | null;
    role: string | null;
    isLoading: boolean;
}

const initialState: AuthState = {
    accessToken: null,
    refreshToken: null,
    user: null,
    role: null,
    isLoading: false,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setTokens: (state, action: any) => {
            const {access_token, refresh_token} = action;
            if (access_token) {
                localStorage.setItem('access_token', access_token);
                state.accessToken = access_token;
            }
            if (refresh_token) {
                localStorage.setItem('refresh_token', refresh_token);
                state.refreshToken = refresh_token;
            }
        },
        resetTokens: (state) => {
            state.accessToken = null;
            state.refreshToken = null;
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('role');
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchSignIn.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchSignIn.fulfilled, (state: any, action: any) => {
                const {credentials: {access_token, refresh_token}, role: {alias}} = action.payload;
                localStorage.setItem('access_token', access_token);
                localStorage.setItem('refresh_token', refresh_token);
                localStorage.setItem('role', alias);
                return {...state, isLoading: false, accessToken: access_token, refreshToken: refresh_token, role: alias};
            })
            .addCase(fetchSignIn.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchUser.fulfilled, (state: any, action: any) => {
                state.user = action.payload.resource;
                state.role = action.payload.resource.role.alias || null;
                localStorage.setItem('role', action.payload.resource.role.alias || null);
            })
            .addCase(fetchLogout.pending, (state: any, action: any) => {
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('role');
                return {...state, accessToken: null, refreshToken: null};
            })
            .addCase(fetchForgotPassword.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchForgotPassword.fulfilled, state => {
                state.isLoading = false;
            })
            .addCase(fetchForgotPassword.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchSetPassword.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchSetPassword.fulfilled, state => {
                state.isLoading = false;
            })
            .addCase(fetchSetPassword.rejected, state => {
                state.isLoading = false;
            })
            .addDefaultCase(() => {
            });
    },
});

export const { setTokens, resetTokens } = authSlice.actions;
export default authSlice.reducer;
