import React, {FC, useEffect, useState} from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { fetchLogout } from '../../../store/auth/authSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/global';
import { ROUTES } from '../../../router/routes';
import headerLogoImg from '../../../assets/images/new_logo.svg';
import logout from '../../../assets/images/icons/logout.svg';
import {fetchUser} from "../../../store/auth/authSlice";
import './HeaderView.scss';

const HeaderView: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  const [menuOpen, setMenuOpen] = useState(false);
  const {role} = useAppSelector(state => state.auth);

  useEffect(() => {
    dispatch(fetchUser());
  }, []);

  const handleLogout = () => {
    dispatch(fetchLogout()).then(resp => {
      navigate('/sign-in');
    });
  };

  return (
    <div className="headerContainer">
      <div className="headerView">
        <div className="headerLogo">
          <img className="headerLogo__img" src={headerLogoImg} alt="Logo" />
        </div>
        <ul className={`headerNavigation ${menuOpen ? 'state_open' : ''}`}>
          <li
            className={`headerNavigation__item ${
              splitLocation[1]?.includes('customer') ? 'state_active' : ''
            }`}
          >
            <Link
              underline="none"
              component={RouterLink}
              className="headerNavigation__link"
              to={ROUTES.CUSTOMERS}
            >
              Customers
            </Link>
          </li>
          <li
            className={`headerNavigation__item ${
                (splitLocation[1]?.includes('affiliate') && !splitLocation[2]?.includes('requests')) ? 'state_active' : ''
            }`}
          >
            <Link
              underline="none"
              component={RouterLink}
              className="headerNavigation__link"
              to={ROUTES.AFFILIATES}
            >
              Channel Partners
            </Link>
          </li>
          <li
              className={`headerNavigation__item ${
                  splitLocation[2]?.includes('requests') ? 'state_active' : ''
              }`}
          >
            <Link
                underline="none"
                component={RouterLink}
                className="headerNavigation__link"
                to={ROUTES.AFFILIATE_REQUESTS}
            >
              Reports
            </Link>
          </li>
          {(role === 'admin') && (
              <>
                <li className={`headerNavigation__item`}>
                  <Button
                      onClick={() => navigate(ROUTES.USERS)}
                      className="headerNavigation__link"
                  >
                    User Management
                  </Button>
                </li>
                <li className={`headerNavigation__item`}>
                  <Button
                      onClick={() => navigate(ROUTES.QUESTIONS)}
                      className="headerNavigation__link"
                  >
                    Questions
                  </Button>
                </li>
                <li className={`headerNavigation__item`}>
                  <Button
                      onClick={() => navigate(ROUTES.DOCUMENTS)}
                      className="headerNavigation__link"
                  >
                    Documents
                  </Button>
                </li>
                {/*<li className={`headerNavigation__item`}>*/}
                {/*  <Button*/}
                {/*      onClick={() => navigate(ROUTES.CATEGORIES)}*/}
                {/*      className="headerNavigation__link"*/}
                {/*  >*/}
                {/*    Page Categories*/}
                {/*  </Button>*/}
                {/*</li>*/}
                {/*<li className={`headerNavigation__item`}>*/}
                {/*  <Button*/}
                {/*      onClick={() => navigate(ROUTES.PAGES)}*/}
                {/*      className="headerNavigation__link"*/}
                {/*  >*/}
                {/*    Pages*/}
                {/*  </Button>*/}
                {/*</li>*/}
              </>
          )}
          {/*<li className={`headerNavigation__item`}>*/}
          {/*  <Button id={'LogOut'} onClick={handleLogout} className="headerNavigation__link">*/}
          {/*    Logout*/}
          {/*  </Button>*/}
          {/*</li>*/}
        </ul>
        <div className="headerHamburger">
          <button
            className={`headerHamburger__btn ${menuOpen ? 'state_active' : ''}`}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <span className="headerHamburger__btnLine"></span>
          </button>
          {menuOpen && (
            <div className="headerHamburger__menu">


              {(role === 'admin') && (
                  <>
                    <div className="headerHamburger__menuMobile">
                      <h3 className="headerHamburger__menuHeader">MENU</h3>
                      <Button onClick={() => navigate(ROUTES.CUSTOMERS)}>
                        Customers
                      </Button>
                      <Button onClick={() => navigate(ROUTES.AFFILIATES)}>
                        Affiliates
                      </Button>
                      <Button onClick={() => navigate(ROUTES.AFFILIATE_REQUESTS)}>
                        Reports
                      </Button>
                    </div>

                    <h3 className="headerHamburger__menuHeader">SYSTEM SETTINGS</h3>
                    <Button onClick={() => navigate(ROUTES.USERS)}>
                      User Management
                    </Button>
                    <Button onClick={() => navigate(ROUTES.QUESTIONS)}>
                      Policy Questions & Guru Guidance
                    </Button>
                    <Button onClick={() => navigate(ROUTES.DOCUMENTS)}>
                      Policy Documents
                    </Button>
                    <Button onClick={() => navigate(ROUTES.CATEGORIES)}>
                      Page Categories
                    </Button><br/>
                    <Button onClick={() => {
                      navigate(ROUTES.PAGES, {state: {category_alias: ''}});
                      setMenuOpen(false);
                    }}>
                      Library Articles
                    </Button>
                    <Button onClick={() => {
                      navigate(ROUTES.PAGES, {state: {category_alias: 'federal-and-state-resources'}});
                      setMenuOpen(false);
                    }}>
                      Federal & State Resources
                    </Button>
                    <br/>
                  </>
              )}
              <Button id={'LogOut'} onClick={handleLogout}>Log Out <img src={logout} alt={''}/></Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderView;
