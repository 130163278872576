import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from '../../service/api';

export const fetchDocuments = createAsyncThunk('document/list', async (params: any) => {
    const response = await api.get('admin/document/list', { params });
    return response.data;
});

export const fetchDocument = createAsyncThunk('document/get', async (id: string) => {
    const response = await api.get(`admin/document/${id}/get`);
    return response.data.resource;
});

export const fetchCreateDocument = createAsyncThunk('document/create', async (params: any) => {
    const response = await api.post('admin/document/create', convertDataToServer(params));
    return response.data?.resource;
});

export const fetchUpdateDocument = createAsyncThunk('document/update', async (params: any) => {
    const {id, data} = params;
    const response = await api.post(`admin/document/${id}/update`, convertDataToServer(data));
    return response.data?.resource;
});

interface  ListFilters {
    order_by: string,
    order_dir: string,
    page: number,
    ipp: number,
    alias: string,
    title: string,
    is_active: number,
}

interface  Pagination {
    total: number,
    current_page: number,
    last_page: number,
    per_page: number,
    from: number,
    to: number,
}

interface QuestionState {
    documents: Array<object>;
    document: any;
    listFilters: ListFilters;
    pagination: Pagination;
    isLoading: boolean;
}

const initialState: QuestionState = {
    documents: [],
    document: null,
    listFilters: {
        order_by: 'id',
        order_dir: 'asc',
        page: 1,
        ipp: 25,
        alias: '',
        title: '',
        is_active: 1,
    },
    pagination: {
        total: 0,
        current_page: 1,
        last_page: 1,
        per_page: 1,
        from: 1,
        to: 1,
    },
    isLoading: false,
};

export const documentSlice = createSlice({
    name: 'document',
    initialState,
    reducers: {
        setFilterParams: (state, action) => {
            state.listFilters = action.payload;
        },
        resetFilters: (state) => {
            state.listFilters = initialState.listFilters;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchDocuments.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchDocuments.fulfilled, (state: any, action: any) => {
                state.documents = action.payload.resource.data;
                state.pagination = {
                    ...state.pagination,
                    current_page: action.payload.resource.current_page,
                    total: action.payload.resource.total,
                    last_page: action.payload.resource.last_page,
                    ipp: action.payload.resource.ipp,
                    page: action.payload.resource.current_page,
                    per_page: action.payload.resource.per_page,
                    from: action.payload.resource.from,
                    to: action.payload.resource.to,
                }
                state.isLoading = false;
            })
            .addCase(fetchDocuments.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchDocument.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchDocument.fulfilled, (state: any, action: any) => {
                state.document = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchDocument.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchCreateDocument.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchCreateDocument.fulfilled, (state: any, action: any) => {
                state.document = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchCreateDocument.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchUpdateDocument.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUpdateDocument.fulfilled, (state: any, action: any) => {
                state.document = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchUpdateDocument.rejected, state => {
                state.isLoading = false;
            })
            .addDefaultCase(() => {
            });
    },
});

const convertDataToServer = (data: any) => {
    const ret: any = {
        alias: data.alias,
        title: data.title,
        content: data.content,
    };

    return ret;
}

export const { setFilterParams, resetFilters } = documentSlice.actions;

export default documentSlice.reducer;
