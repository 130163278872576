import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from '../../service/api';

export const fetchStates = createAsyncThunk('general/states', async (_, {rejectWithValue, dispatch}) => {
    const response = await api.get('general/states');
    dispatch(setStates(response.data.resource));
});

export const fetchFileUpload = createAsyncThunk('file/upload', async (file: any) => {
    const formData = new FormData();
    formData.append('file', file);
    const response = await api.post('general/file/upload', formData);
    return response.data;
});

interface GlobalState {
    isLoading: boolean;
    states: any[],
    uploader: any
}

const initialState: GlobalState = {
    isLoading: false,
    states: [],
    uploader: null
};

export const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        setStates: (state, action) => {
            state.states = action.payload;
        },
        resetUploader: (state) => {
            state.uploader = initialState.uploader;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchFileUpload.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchFileUpload.fulfilled, (state: any, action: any) => {
                state.uploader = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchFileUpload.rejected, state => {
                state.isLoading = false;
            })
            .addDefaultCase(state=> {
                state.isLoading = false;
            });
    },
});

export const {setStates, resetUploader} = generalSlice.actions;
export default generalSlice.reducer;
