import React, {FC, useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import Button from '@mui/material/Button';
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import {Input as MUIInput} from '@mui/material';
import {Input, Select, Textarea} from "../../controls";
import './AffiliateForm.scss';

type Props = {
    affiliate?: any
    handleOnSubmit: (data: any) => void
};

const statusOptions = [
    {value: '1', label: 'Active'},
    {value: '0', label: 'Archived'},
]

const AffiliateForm: FC<Props> = ({
     affiliate,
     handleOnSubmit
 }) => {
    const [commissionType, setCommissionType] = useState('percent');
    const {control, handleSubmit, reset, setValue, formState: {errors}} = useForm({
        mode: 'onBlur',
        defaultValues: {
            email: '',
            is_active: '1',
            first_name: '',
            last_name: '',
            company: '',
            notes: '',
            non_reg_price: '',
            dot_fmcsa_price: '',
            non_reg_dot_fmcsa_price: '',
            commission_type: '',
            commission_value: '',
            phone: '',
        }
    });

    useEffect(() => {
        reset({
            email: affiliate?.email || '',
            is_active: affiliate ? (affiliate?.is_active ? '1' : '0') : '1',
            first_name: affiliate?.profile.first_name || '',
            last_name: affiliate?.profile.last_name || '',
            company: affiliate?.profile.company || '',
            notes: affiliate?.profile.notes || '',
            non_reg_price: affiliate?.profile.non_reg_price || '',
            dot_fmcsa_price: affiliate?.profile.dot_fmcsa_price || '',
            non_reg_dot_fmcsa_price: affiliate?.profile.non_reg_dot_fmcsa_price || '',
            commission_type: affiliate?.profile.commission_type || 'percent',
            commission_value: affiliate?.profile.commission_value || '',
            phone: affiliate?.address?.phone || '',
        });
        setCommissionType(affiliate?.profile.commission_type || 'percent');
    }, [affiliate]);

    const handleCommissionType = (value: string) => {
        setCommissionType(value);
        setValue('commission_type', value);
        setValue('commission_value', '');
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(affiliate?.profile.affiliate_link)
    }

    return (
        <form className="formContainer">
            <h3 className="formContainer__title mt-0">Channel Partner Information</h3>
            <div className="formContainer__row -grid-4">
                <Select
                    control={control}
                    name={'is_active'}
                    title={'Channel Partner Status*'}
                    options={statusOptions}
                />
                <Input
                    control={control}
                    name={'first_name'}
                    title={'first name*'}
                    placeholder={'Enter First Name'}
                    rules={{
                        required: "This field is required",
                    }}
                    inputProps={{maxLength: 50}}
                />
                <Input
                    control={control}
                    name={'last_name'}
                    title={'Last name*'}
                    placeholder={'Enter Last Name'}
                    rules={{
                        required: "This field is required",
                    }}
                    inputProps={{maxLength: 50}}
                />
            </div>
            <h3 className="formContainer__title">Contact Information</h3>
            <div className="formContainer__row -grid-4">
                <Input
                    control={control}
                    name={'phone'}
                    title={'Phone Number*'}
                    placeholder={'Enter Phone Number'}
                    type={'phone'}
                    rules={{
                        required: "This field is required",
                    }}
                />
                <Input
                    control={control}
                    name={'email'}
                    title={'Email Address*'}
                    placeholder={'Enter Email Address'}
                    rules={{
                        required: "This field is required",
                        pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Invalid Email Address"
                        }
                    }}
                    inputProps={{maxLength: 100}}
                />
            </div>
            <h3 className="formContainer__title">Company Information</h3>
            <div className="formContainer__row -grid-4">
                <Input
                    control={control}
                    name={'company'}
                    title={'Company Name*'}
                    placeholder={'Enter Company Name'}
                    inputProps={{maxLength: 100}}
                    rules={{
                        required: "This field is required",
                    }}
                />
            </div>
            {affiliate?.id && (
                <>
                    <h3 className="formContainer__title">Unique Channel Partner Link</h3>
                    <div className="formContainer__row -grid-2">
                        <FormControl className="inputField -linkCopy">
                            <MUIInput
                                disabled={true}
                                className="inputField__input"
                                value={affiliate?.profile.affiliate_link}
                            />
                            <IconButton onClick={copyToClipboard}>
                                <ContentCopyIcon color="primary" />
                            </IconButton>
                        </FormControl>
                    </div>
                </>
            )}
            <h3 className="formContainer__title">Channel Partner Settings</h3>
            <div className="formContainer__row -grid-4">
                <Input
                    control={control}
                    name={'non_reg_price'}
                    title={'Non-Regulated Docs Price*'}
                    placeholder={''}
                    type={'currency'}
                    inputProps={{maxLength: 8}}
                    rules={{
                        required: "This field is required",
                    }}
                />
                <Input
                    control={control}
                    name={'dot_fmcsa_price'}
                    title={'DOT/FMCSA Regulated Docs Price*'}
                    placeholder={''}
                    type={'currency'}
                    inputProps={{maxLength: 8}}
                    rules={{
                        required: "This field is required",
                    }}
                />
                <Input
                    control={control}
                    name={'non_reg_dot_fmcsa_price'}
                    title={'Non-Reg & DOT/FMCSA Reg Docs Price*'}
                    placeholder={''}
                    type={'currency'}
                    inputProps={{maxLength: 8}}
                    rules={{
                        required: "This field is required",
                    }}
                />
            </div>
            <div className="formContainer__row -grid-1">
                <RadioGroup
                    row
                    aria-labelledby="row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                >
                    <FormControlLabel
                        value="percent"
                        control={
                            <Radio checked={commissionType === 'percent'} onClick={() => handleCommissionType('percent')} />
                        }
                        label={'Commission Percent (%)'}
                    />
                    <FormControlLabel
                        value="flat"
                        control={
                            <Radio checked={commissionType === 'flat'} onClick={() => handleCommissionType('flat')} />
                        }
                        label={'Commission Flat Fee ($)'}
                    />
                </RadioGroup>
            </div>
            <div className="formContainer__row -grid-4">
                <Input
                    control={control}
                    name={'commission_value'}
                    title={commissionType === 'percent' ? 'Commission Percent (%) Amount*' :  'Commission Flat Fee ($) Amount*'}
                    placeholder={''}
                    type={'currency'}
                    maxValue={commissionType === 'flat' ? 1000 : 100}
                    decimalScale={commissionType === 'flat' ? 2 : 0}
                    inputProps={{maxLength: 8}}
                    prefix={commissionType === 'flat' ? '$ ' : ''}
                    suffix={commissionType === 'percent' ? ' %' : ''}
                    rules={{
                        required: "This field is required",
                    }}
                />
            </div>
            <div className="formContainer__row">
                <Textarea
                    control={control}
                    name={'notes'}
                    title={'Channel Partner Notes'}
                    placeholder={''}
                />
            </div>
            <Button
                onClick={handleSubmit((data) => handleOnSubmit(data))}
                variant="contained"
                className="simpleBtn"
            >
                Save
            </Button>
        </form>
    );
};

export default AffiliateForm;

