import { createTheme } from '@mui/material';
import { mainBlue } from '../../constants/colors';

export const theme = createTheme({
  palette: {
    primary: {
      main: mainBlue,
    }
  },
});
