import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from '../../service/api';

export const fetchPages = createAsyncThunk('page/list', async (params: any) => {
    const response = await api.get('admin/page/list', { params });
    return response.data;
});

export const fetchPage = createAsyncThunk('page/get', async (id: string) => {
    const response = await api.get(`admin/page/${id}/get`);
    return response.data.resource;
});

export const fetchCreatePage = createAsyncThunk('page/create', async (params: any) => {
    const {data} = params;
    const response = await api.post('admin/page/create', convertDataToServer(data));
    return response.data?.resource;
});

export const fetchUpdatePage = createAsyncThunk('page/update', async (params: any) => {
    const {id, data} = params;
    const response = await api.post(`admin/page/${id}/update`, convertDataToServer(data));
    return response.data?.resource;
});

export const fetchDeletePage = createAsyncThunk('page/delete', async (params: any) => {
    const {id} = params;
    const response = await api.post(`admin/page/${id}/delete`);
    return response.data?.resource;
});

interface  ListFilters {
    order_by: string,
    order_dir: string,
    page: number,
    ipp: number,
    category_id: number | null,
    title: string,
    is_active: string,
}

interface  Pagination {
    total: number,
    current_page: number,
    last_page: number,
    per_page: number,
    from: number,
    to: number,
}

interface PageState {
    pages: Array<object>;
    page: any;
    listFilters: ListFilters;
    pagination: Pagination;
    isLoading: boolean;
}

const initialState: PageState = {
    pages: [],
    page: null,
    listFilters: {
        order_by: 'id',
        order_dir: 'asc',
        page: 1,
        ipp: 25,
        category_id: null,
        title: '',
        is_active: '',
    },
    pagination: {
        total: 0,
        current_page: 1,
        last_page: 1,
        per_page: 1,
        from: 1,
        to: 1,
    },
    isLoading: false,
};

export const pageSlice = createSlice({
    name: 'page',
    initialState,
    reducers: {
        setFilterParams: (state, action) => {
            state.listFilters = action.payload;
        },
        resetFilters: (state) => {
            state.listFilters = initialState.listFilters;
        },
        resetPage: (state) => {
            state.page = initialState.page;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchPages.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchPages.fulfilled, (state: any, action: any) => {
                state.pages = action.payload.resource.data;
                state.pagination = {
                    ...state.pagination,
                    current_page: action.payload.resource.current_page,
                    total: action.payload.resource.total,
                    last_page: action.payload.resource.last_page,
                    ipp: action.payload.resource.ipp,
                    page: action.payload.resource.current_page,
                    per_page: action.payload.resource.per_page,
                    from: action.payload.resource.from,
                    to: action.payload.resource.to,
                }
                state.isLoading = false;
            })
            .addCase(fetchPages.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchPage.fulfilled, (state: any, action: any) => {
                state.page = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchPage.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchCreatePage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchCreatePage.fulfilled, (state: any, action: any) => {
                state.page = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchCreatePage.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchUpdatePage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUpdatePage.fulfilled, (state: any, action: any) => {
                state.page = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchUpdatePage.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchDeletePage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchDeletePage.fulfilled, (state: any, action: any) => {
                state.isLoading = false;
            })
            .addCase(fetchDeletePage.rejected, state => {
                state.isLoading = false;
            })
            .addDefaultCase(state => {
                state.isLoading = false;
            });
    },
});

const convertDataToServer = (data: any) => {
    const ret: any = {
        category_id: data.category_id,
        alias: data.alias,
        type: data.type,
        title: data.title,
        content: data.content,
        is_active: data.is_active,
    };

    if (data.file) {
        ret['document'] = {
            file: data.file.filepath,
            title: data.file.original_name,
        }
    }

    return ret;
}

export const { setFilterParams, resetFilters, resetPage } = pageSlice.actions;

export default pageSlice.reducer;
