import React, {FC, useEffect, useRef, useState} from 'react';
import {useForm} from "react-hook-form";
import {Link as RouterLink, useLocation, useNavigate} from "react-router-dom";
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import {useAppDispatch, useAppSelector} from '../../../hooks/global';
import {fetchDocuments, setFilterParams, resetFilters} from "../../../store/document/documentSlice";
import {ROUTES} from "../../../router/routes";
import HeaderView from '../../common/Header/HeaderView'
import FooterView from '../../common/Footer/FooterView'
import TopBanner from '../../common/TopBanner/TopBanner'
import DataTableView from "../../common/DataTable/DataTableView";
import {Input, Select, Autocomplete} from "../../controls";
import Spinner from "../../common/Spinner/Spinner";
import './Documents.scss'

const columns = [
    {
        name: 'ID',
        alias: 'id',
        selector: (row: any) => row.id,
        sortable: true,
        width: '80px',
    },
    {
        name: 'Alias',
        alias: 'alias',
        selector: (row: any) => row.alias,
        sortable: true,
        width: '300px',
    },
    {
        name: 'Title',
        alias: 'title',
        selector: (row: any) => row.title,
        sortable: true,
    },
    {
        name: 'Part',
        alias: 'part',
        selector: (row: any) => row.part,
        sortable: true,
        width: '150px',
    },
    // {
    //     name: 'Status',
    //     alias: 'is_active',
    //     selector: (row: any) => row.is_active,
    //     sortable: true,
    //     width: '130px',
    //     cell: (row: any) =>
    //         <span className={`status
    //     ${row.is_active === 1 ? '-active' : ''}
    //     ${row.is_active === 0 ? '-archived' : ''}`}>
    //       {row.is_active ? 'Active' : 'Archived'}
    //       </span>
    // },
    {
        name: 'Edit',
        alias: 'edit',
        selector: (row: any) => null,
        sortable: false,
        width: '60px',
        cell: (row: any) => <Link
            component={RouterLink}
            className="editLink"
            to={`/document/${row.id}`}
        >
            <span className="editLink__icon"/>
        </Link>
    },
];

const statusOptions = [
    {value: '1', label: 'Active'},
    {value: '0', label: 'Archived'},
]

const Documents: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const didMountRef = useRef(false);
    const {documents, listFilters, pagination, isLoading} = useAppSelector(state => state.document);
    const keepFilters = location.state?.keepFilters || false;
    const {control, handleSubmit, reset } = useForm({
        mode: 'onBlur',
        defaultValues: {
            alias: listFilters.alias,
            totle: listFilters.title,
            //is_active: listFilters.is_active,
        }
    });

    useEffect(() => {
        didMountRef.current = true;
        if (!keepFilters) handleReset();
    }, []);

    useEffect(() => {
        if (didMountRef.current) {
            dispatch(fetchDocuments(listFilters));
        }
    }, [listFilters]);

    const handleSearch = (data: any) => {
        dispatch(setFilterParams({...listFilters, ...data, page: 1}));
    };

    const handleReset = () => {
        const data = {
            alias: '',
            title: '',
            //is_active: 1,
        };
        reset(data);
        dispatch(setFilterParams({...listFilters, ...data, page: 1}));
    };

    const handleChangePage = (page: number) => {
        dispatch(setFilterParams({...listFilters, page}));
    };

    const handleSort = (order_by: string, order_dir: string) => {
        dispatch(setFilterParams({...listFilters, order_by, order_dir, page: 1}));
    };

    return (
        <div className="contentWrapper">
            <HeaderView/>
            <Spinner isLoading={isLoading}/>
            <div className="pageContainer">
                <TopBanner/>
                <div className="pageContainer__view">
                    <div className="pageHeader">
                        <div className="pageHeader__rowTitle">
                            <h2 className="pageHeader__title">Documents</h2>
                            {/*<Button*/}
                            {/*    onClick={() => navigate(ROUTES.QUESTION_CREATE)}*/}
                            {/*    variant="contained"*/}
                            {/*    className="pageHeader__btn">*/}
                            {/*    <span className="pageHeader__btnIcon -plus"></span>*/}
                            {/*    Add New Question*/}
                            {/*</Button>*/}
                        </div>
                    </div>
                    <form className="formContainer">
                        <div className="formContainer__row -grid-4">
                            <Input
                                control={control}
                                name={'alias'}
                                title={'Alias'}
                                placeholder={'Alias'}
                            />
                            <Input
                                control={control}
                                name={'title'}
                                title={'Title'}
                                placeholder={'Title'}
                            />
                            {/*<Select*/}
                            {/*    control={control}*/}
                            {/*    name={'is_active'}*/}
                            {/*    title={'Question Status'}*/}
                            {/*    options={statusOptions}*/}
                            {/*/>*/}
                        </div>
                        <Button
                            onClick={handleSubmit(handleSearch)}
                            type={"submit"}
                            variant="contained"
                            className="simpleBtn -blue">
                            search
                        </Button>
                        <Button
                            onClick={handleReset}
                            variant="contained"
                            className="simpleBtn -ml-10">
                            Reset
                        </Button>
                    </form>
                    <div className="tableList -customer">
                        <h3 className="tableList__title">Search Results</h3>
                        <DataTableView
                            data={documents}
                            columns={columns}
                            currentPage={pagination.current_page}
                            paginationTotalRows={pagination.total}
                            paginationPerPage={pagination.per_page}
                            lastPage={pagination.last_page}
                            from={pagination.from}
                            to={pagination.to}
                            onChangePage={(page) => handleChangePage(page)}
                            onSort={handleSort}
                        />
                    </div>
                </div>
            </div>
            <FooterView/>
        </div>
    );
};

export default Documents;
