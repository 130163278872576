import React, {FC, useEffect, useRef, useState} from 'react';
import {useForm, useWatch} from "react-hook-form";
import {Input, Select, Textarea, TinyMceEditor} from "../../controls";
import RadioGroup from "@mui/material/RadioGroup";
import Button from '@mui/material/Button';
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import './QuestionForm.scss';

type Props = {
    question?: any
    handleOnSubmit: (data: any) => void
};

const statusOptions = [
    {value: '1', label: 'Active'},
    {value: '0', label: 'Archived'},
]

const answerOptions = [
    {value: 'string', label: 'String (Input)'},
    {value: 'bool', label: 'Yes/No (Radio Buttons)'},
    {value: 'bool-extra', label: 'Yes/No (Radio Buttons Extended)'},
    {value: 'memo', label: 'Memo (Textarea)'},
    {value: 'checkboxes', label: 'Multiple choice (Checkboxes)'},
    {value: 'radios', label: 'Single choice (Radio Buttons)'},
    {value: 'date', label: 'Date'},
]

const QuestionForm: FC<Props> = ({
     question,
     handleOnSubmit
 }) => {
    const editorRef = useRef(null);
    const editor2Ref = useRef(null);
    const editor3Ref = useRef(null);
    const {control, handleSubmit, reset, setValue, getValues, formState: {errors}} = useForm({
        mode: 'onBlur',
        defaultValues: {
            code: '',
            is_active: '1',
            question: '',
            answer_type: '',
            options: '',
            discussion: '',
            short_discussion: '',
            introduction: '',
            is_discussion: false,
            is_short_discussion: false,
            is_introduction: false,
        }
    });

    useEffect(() => {
        reset({
            code: question?.code || '',
            is_active: question ? (question?.is_active ? '1' : '0') : '1',
            is_discussion: !!question?.is_discussion,
            is_short_discussion: !!question?.is_short_discussion,
            is_introduction: !!question?.is_introduction,
            question: question?.question || '',
            answer_type: question?.answer_type || '',
            options: question?.options || '',
            discussion: question?.discussion || '',
            short_discussion: question?.short_discussion || '',
            introduction: question?.introduction || '',
        });
    }, [question]);

    const answerType = useWatch({
        control,
        name: 'answer_type',
    });

    const isDiscussion = useWatch({
        control,
        name: 'is_discussion',
    });

    const isShortDiscussion = useWatch({
        control,
        name: 'is_short_discussion',
    });

    const isIntroduction = useWatch({
        control,
        name: 'is_introduction',
    });

    const discussion = useWatch({
        control,
        name: 'discussion',
    });

    const short_discussion = useWatch({
        control,
        name: 'short_discussion',
    });

    const introduction = useWatch({
        control,
        name: 'introduction',
    });

    const handleDiscussion = (value: boolean) => {
        setValue('is_discussion', value);
    }

    const handleShortDiscussion = (value: boolean) => {
        setValue('is_short_discussion', value);
    }

    const handleIntroduction = (value: boolean) => {
        setValue('is_introduction', value);
    }

    const handleChangeEditor = () => {
        if (editorRef.current) {
            // @ts-ignore
            setValue('discussion', editorRef.current?.getContent());
        }
    }

    const handleChangeEditor2 = () => {
        if (editor2Ref.current) {
            // @ts-ignore
            setValue('short_discussion', editor2Ref.current?.getContent());
        }
    }

    const handleChangeEditor3 = () => {
        if (editor3Ref.current) {
            // @ts-ignore
            setValue('introduction', editor3Ref.current?.getContent());
        }
    }

    return (
        <form className="formContainer">
            <div className="formContainer__row -grid-2">
                <Input
                    control={control}
                    name={'question'}
                    title={'Question Text*'}
                    placeholder={'Enter Question Text'}
                    rules={{
                        required: "This field is required",
                    }}
                    inputProps={{maxLength: 50}}
                />
            </div>
            <div className="formContainer__row -grid-4">
                <Input
                    control={control}
                    name={'code'}
                    title={'Question Code*'}
                    placeholder={'Enter Question Code'}
                    rules={{
                        required: "This field is required",
                    }}
                    inputProps={{maxLength: 50}}
                />
                <Select
                    control={control}
                    name={'is_active'}
                    title={'Question Status*'}
                    options={statusOptions}
                />
            </div>
            <div className="formContainer__row -grid-4">
                <Select
                    control={control}
                    name={'answer_type'}
                    title={'Answer Type?*'}
                    options={answerOptions}
                />
                {(answerType === 'checkboxes' || answerType === 'radios') && (
                    <Textarea
                        control={control}
                        name={'options'}
                        title={'Answer Options List'}
                        placeholder={''}
                        rules={{
                            required: "This field is required",
                        }}
                    />
                )}
                {(answerType === 'memo') && (
                    <Textarea
                        control={control}
                        name={'options'}
                        title={'Answer Options List'}
                        placeholder={''}
                      />
                )}
            </div>
            <div className="formContainer__row -grid-1">
                <div>
                    <label className="inputField__title form-label">Question contains a Guru Guidance?</label>
                    <RadioGroup
                        row
                        aria-labelledby="row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                    >
                        <FormControlLabel
                            value="percent"
                            control={
                                <Radio checked={!isDiscussion} onClick={() => handleDiscussion(false)} />
                            }
                            label={'No'}
                        />
                        <FormControlLabel
                            value="flat"
                            control={
                                <Radio checked={isDiscussion} onClick={() => handleDiscussion(true)} />
                            }
                            label={'Yes'}
                        />
                    </RadioGroup>
                </div>
            </div>
            {isDiscussion && (
                <>
                    <div className="formContainer__row -grid-1">
                        <TinyMceEditor
                            editorRef={editorRef}
                            value={discussion}
                            onChange={handleChangeEditor}
                        />
                    </div>

                    <div className="formContainer__row -grid-1">
                        <div>
                            <label className="inputField__title">Short Guru Guidance?</label>
                            <RadioGroup
                                row
                                aria-labelledby="row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel
                                    value="percent"
                                    control={
                                        <Radio checked={!isShortDiscussion} onClick={() => handleShortDiscussion(false)} />
                                    }
                                    label={'No'}
                                />
                                <FormControlLabel
                                    value="flat"
                                    control={
                                        <Radio checked={isShortDiscussion} onClick={() => handleShortDiscussion(true)} />
                                    }
                                    label={'Yes'}
                                />
                            </RadioGroup>
                        </div>
                    </div>

                    {isShortDiscussion && (
                        <div className="formContainer__row -grid-1">
                            <TinyMceEditor
                                editorRef={editor2Ref}
                                value={short_discussion}
                                onChange={handleChangeEditor2}
                            />
                        </div>
                    )}
                </>
            )}
            <div className="formContainer__row -grid-1">
                <div>
                    <label className="inputField__title form-label">Question contains an Introduction?</label>
                    <RadioGroup
                        row
                        aria-labelledby="row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                    >
                        <FormControlLabel
                            value="percent"
                            control={
                                <Radio checked={!isIntroduction} onClick={() => handleIntroduction(false)} />
                            }
                            label={'No'}
                        />
                        <FormControlLabel
                            value="flat"
                            control={
                                <Radio checked={isIntroduction} onClick={() => handleIntroduction(true)} />
                            }
                            label={'Yes'}
                        />
                    </RadioGroup>
                </div>
            </div>
            {isIntroduction && (
                <div className="formContainer__row -grid-1">
                    <TinyMceEditor
                        editorRef={editor3Ref}
                        value={introduction}
                        onChange={handleChangeEditor3}
                    />
                </div>
            )}
            <Button
                onClick={handleSubmit((data) => handleOnSubmit(data))}
                variant="contained"
                className="simpleBtn"
            >
                Save
            </Button>
        </form>
    );
};

export default QuestionForm;

