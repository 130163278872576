import React, {FC} from 'react';
import './ErrorToast.scss'

interface Prop {
    message: string;
    errors: Array<string> | string;
}

const ErrorToast: FC<Prop> = ({message, errors}) => {
    return (
        <>
            <div className={"message"}>{message}</div>
            {(errors && typeof errors === 'object' && !!Object.keys(errors).length) &&
            <div>
                <ul className={"list"}>
                    {Object.keys(errors).map((key: any, i) => (
                        <li key={i}>{errors[key]}</li>
                    ))}
                </ul>
            </div>
            }
        </>
    );
};

export default ErrorToast;
