import React, {FC, useEffect, useState} from 'react';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import {useAppDispatch, useAppSelector} from "../../../hooks/global";
import {fetchCustomer, fetchUpdateCustomer} from "../../../store/customer/customerSlice";
import {ROUTES} from '../../../router/routes';
import HeaderView from '../../common/Header/HeaderView';
import FooterView from '../../common/Footer/FooterView';
import TopBanner from '../../common/TopBanner/TopBanner';
import {CustomerForm} from "../../common";
import Spinner from "../../common/Spinner/Spinner";

const CustomerEdit: FC = () => {
    const {id} = useParams();
    const navigation = useNavigate();
    const dispatch = useAppDispatch();
    const {customer, isLoading} = useAppSelector(state => state.customer);

    useEffect(() => {
        if (id) dispatch(fetchCustomer(id));
    }, [id]);

    const handleSubmit = (data: any) => {
        dispatch(fetchUpdateCustomer({id, data})).then(resp => {
            if (resp?.type === 'customer/update/fulfilled') {
                navigation('/customers', {state: {keepFilters: true}});
            }
        });
    };

    const getCustomerName = (customer: any): string => {
        return `${customer?.profile.first_name} ${customer?.profile.last_name}`
    }

    return (
        <div className="contentWrapper">
            <HeaderView/>
            <Spinner isLoading={isLoading}/>
            <div className="pageContainer">
                <TopBanner/>
                <div className="pageContainer__view">
                    <div className="pageHeader">
                        <div className="pageHeader__rowTitle">
                            <ul className="pageHeader__tabList">
                                <li className="pageHeader__tabItem state_active">
                                    <Link
                                        underline="hover"
                                        component={RouterLink}
                                        className="pageHeader__tabLink"
                                        to={''}
                                    >
                                        Customer Details
                                    </Link>
                                </li>
                                <li className="pageHeader__tabItem">
                                    <Link
                                        underline="hover"
                                        component={RouterLink}
                                        className="pageHeader__tabLink"
                                        to={`/customer/${id}/history`}
                                    >
                                        Purchase History
                                    </Link>
                                </li>
                            </ul>
                            <Button
                                onClick={() => navigation('/customers', {state: {keepFilters: true}})}
                                variant="contained"
                                className="pageHeader__btn -desktop"
                            >
                                <span className="pageHeader__btnIcon -back"></span>
                                Back
                            </Button>
                        </div>
                        <div className="pageHeader__rowSubTitle">{getCustomerName(customer)}</div>
                        <Button
                            onClick={() => navigation('/customers', {state: {keepFilters: true}})}
                            variant="contained"
                            className="pageHeader__btn -mobile"
                        >
                            <span className="pageHeader__btnIcon -back"/>
                            Back
                        </Button>
                    </div>
                    <CustomerForm
                        customer={customer}
                        handleOnSubmit={handleSubmit}
                    />
                </div>
            </div>
            <FooterView/>
        </div>
    );
};

export default CustomerEdit;
