import React, {FC, useEffect, useRef, useState} from 'react';
import {useForm} from "react-hook-form";
import {Link as RouterLink, useLocation, useNavigate} from "react-router-dom";
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import {useAppDispatch, useAppSelector} from '../../../hooks/global';
import {fetchDeletePage, fetchPages, setFilterParams} from "../../../store/page/pageSlice";
import {fetchCategoryOptions} from "../../../store/category/categorySlice";
import HeaderView from '../../common/Header/HeaderView'
import FooterView from '../../common/Footer/FooterView'
import TopBanner from '../../common/TopBanner/TopBanner'
import DataTableView from "../../common/DataTable/DataTableView";
import {Input, Select} from "../../controls";
import Spinner from "../../common/Spinner/Spinner";
import {ROUTES} from "../../../router/routes";
import {ConfirmationModal} from "../../common";
import './Pages.scss'

const statusOptions = [
    {value: '', label: 'All Pages'},
    {value: '1', label: 'Active'},
    {value: '0', label: 'Archived'},
]

const Pages: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const didMountRef = useRef(false);
    const {pages, listFilters, pagination, isLoading} = useAppSelector(state => state.page);
    const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
    const [idForDelete, setIdForDelete] = useState(null);
    const {options} = useAppSelector(state => state.category);
    const keepFilters = location.state?.keepFilters || false;
    const categoryAlias = location.state?.category_alias || null;
    const {control, handleSubmit, reset, setValue } = useForm({
        mode: 'onBlur',
        defaultValues: {
            category_id: listFilters.category_id,
            title: listFilters.title,
            is_active: listFilters.is_active,
        }
    });

    const columns = [
        {
            name: 'ID',
            alias: 'id',
            selector: (row: any) => row.id,
            sortable: true,
            width: '80px',
        },
        {
            name: 'Title',
            alias: 'title',
            selector: (row: any) => row.title,
            sortable: true,
            width: '350px',
        },
        {
            name: 'Category',
            alias: 'category',
            selector: (row: any) => (row.category ? ((row.category?.parent ? row.category?.parent.title+' --- ' : '')+row.category?.title) : ''),
            sortable: false,
        },
        {
            name: 'Status',
            alias: 'is_active',
            selector: (row: any) => row.is_active,
            sortable: true,
            width: '130px',
            cell: (row: any) =>
                <span className={`status
        ${row.is_active === 1 ? '-active' : ''}
        ${row.is_active === 0 ? '-archived' : ''}`}>
          {row.is_active ? 'Active' : 'Archived'}
          </span>
        },
        {
            name: 'Edit',
            alias: 'edit',
            selector: (row: any) => null,
            sortable: false,
            width: '60px',
            cell: (row: any) => <Link
                component={RouterLink}
                className="editLink"
                to={`/page/${row.id}`}
            >
                <span className="editLink__icon"/>
            </Link>
        },
        {
            name: 'Delete',
            alias: 'delete',
            selector: (row: any) => null,
            sortable: false,
            width: '80px',
            cell: (row: any) => <span className="deleteLink__icon" onClick={()=>{
                setIdForDelete(row.id);
                setConfirmModalOpen(true);
            }}/>
        },
    ];

    useEffect(() => {
        didMountRef.current = true;
        dispatch(fetchCategoryOptions({}));
        if (!keepFilters) handleReset();
    }, []);

    useEffect(() => {
        if (didMountRef.current) {
            dispatch(fetchPages(listFilters));
        }
    }, [listFilters]);

    useEffect(() => {
        if (options) {
            const cat = options.find((item: any) => item.alias === categoryAlias);
            setValue('category_id', cat ? Number(cat.value) : null);
            dispatch(setFilterParams({...listFilters, page: 1, category_id: cat ? cat.value : null}));
        }
    }, [options, categoryAlias]);

    const handleSearch = (data: any) => {
        dispatch(setFilterParams({...listFilters, ...data, page: 1}));
    };

    const handleReset = () => {
        const data = {
            category_id: null,
            title: '',
            is_active: '',
        };
        reset(data);
        dispatch(setFilterParams({...listFilters, ...data, page: 1}));
    };

    const handleChangePage = (page: number) => {
        dispatch(setFilterParams({...listFilters, page}));
    };

    const handleSort = (order_by: string, order_dir: string) => {
        dispatch(setFilterParams({...listFilters, order_by, order_dir, page: 1}));
    };

    const handleDelete = () => {
        if (idForDelete) {
            dispatch(fetchDeletePage({ id: idForDelete })).then(resp => {
                if (resp?.type === 'page/delete/fulfilled') {
                    dispatch(fetchPages(listFilters));
                }
            });
        }
        setConfirmModalOpen(false);
        setIdForDelete(null);
    };

    return (
        <div className="contentWrapper">
            <HeaderView/>
            <Spinner isLoading={isLoading}/>
            <div className="pageContainer">
                <TopBanner/>
                <div className="pageContainer__view">
                    <div className="pageHeader">
                        <div className="pageHeader__rowTitle">
                            <h2 className="pageHeader__title">Pages</h2>
                            <Button
                                onClick={() => navigate(ROUTES.PAGE_CREATE)}
                                variant="contained"
                                className="pageHeader__btn">
                                <span className="pageHeader__btnIcon -plus"/>
                                Add New Page
                            </Button>
                        </div>
                    </div>
                    <form className="formContainer">
                        <div className="formContainer__row -grid-4">
                            <Input
                                control={control}
                                name={'title'}
                                title={'Title'}
                                placeholder={'Title'}
                            />
                            <Select
                                control={control}
                                name={'is_active'}
                                title={'Status'}
                                options={statusOptions}
                            />
                        </div>
                        <div className="formContainer__row -grid-2">
                            <Select
                                className={'selectField__maxWidth600'}
                                control={control}
                                name={'category_id'}
                                title={'Category'}
                                options={options}
                                placeholder={'--- Select One ---'}
                            />
                        </div>
                        <Button
                            onClick={handleSubmit(handleSearch)}
                            type={"submit"}
                            variant="contained"
                            className="simpleBtn -blue">
                            search
                        </Button>
                        <Button
                            onClick={handleReset}
                            variant="contained"
                            className="simpleBtn -ml-10">
                            Reset
                        </Button>
                    </form>
                    <div className="tableList -customer">
                        <h3 className="tableList__title">Search Results</h3>
                        <DataTableView
                            data={pages}
                            columns={columns}
                            currentPage={pagination.current_page}
                            paginationTotalRows={pagination.total}
                            paginationPerPage={pagination.per_page}
                            lastPage={pagination.last_page}
                            from={pagination.from}
                            to={pagination.to}
                            onChangePage={(page) => handleChangePage(page)}
                            onSort={handleSort}
                        />
                    </div>
                </div>
            </div>
            <ConfirmationModal
                modalIsOpen={isConfirmModalOpen}
                onConfirm={handleDelete}
                onCancel={()=>{
                    setIdForDelete(null);
                    setConfirmModalOpen(false)
                }}
                modalText={'Do you really want to delete?'}
            />
            <FooterView/>
        </div>
    );
};

export default Pages;
