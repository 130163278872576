import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from '../../service/api';

export const fetchQuestions = createAsyncThunk('question/list', async (params: any) => {
    const response = await api.get('admin/question/list', { params });
    return response.data;
});

export const fetchQuestion = createAsyncThunk('question/get', async (id: string) => {
    const response = await api.get(`admin/question/${id}/get`);
    return response.data.resource;
});

export const fetchCreateQuestion = createAsyncThunk('question/create', async (params: any) => {
    const response = await api.post('admin/question/create', convertDataToServer(params));
    return response.data?.resource;
});

export const fetchUpdateQuestion = createAsyncThunk('question/update', async (params: any) => {
    const {id, data} = params;
    const response = await api.post(`admin/question/${id}/update`, convertDataToServer(data));
    return response.data?.resource;
});

interface  ListFilters {
    order_by: string,
    order_dir: string,
    page: number,
    ipp: number,
    code: string,
    is_active: number,
}

interface  Pagination {
    total: number,
    current_page: number,
    last_page: number,
    per_page: number,
    from: number,
    to: number,
}

interface QuestionState {
    questions: Array<object>;
    question: any;
    listFilters: ListFilters;
    pagination: Pagination;
    isLoading: boolean;
}

const initialState: QuestionState = {
    questions: [],
    question: null,
    listFilters: {
        order_by: 'code',
        order_dir: 'asc',
        page: 1,
        ipp: 25,
        code: '',
        is_active: 1,
    },
    pagination: {
        total: 0,
        current_page: 1,
        last_page: 1,
        per_page: 1,
        from: 1,
        to: 1,
    },
    isLoading: false,
};

export const questionSlice = createSlice({
    name: 'question',
    initialState,
    reducers: {
        setFilterParams: (state, action) => {
            state.listFilters = action.payload;
        },
        resetFilters: (state) => {
            state.listFilters = initialState.listFilters;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchQuestions.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchQuestions.fulfilled, (state: any, action: any) => {
                state.questions = action.payload.resource.data;
                state.pagination = {
                    ...state.pagination,
                    current_page: action.payload.resource.current_page,
                    total: action.payload.resource.total,
                    last_page: action.payload.resource.last_page,
                    ipp: action.payload.resource.ipp,
                    page: action.payload.resource.current_page,
                    per_page: action.payload.resource.per_page,
                    from: action.payload.resource.from,
                    to: action.payload.resource.to,
                }
                state.isLoading = false;
            })
            .addCase(fetchQuestions.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchQuestion.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchQuestion.fulfilled, (state: any, action: any) => {
                state.question = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchQuestion.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchCreateQuestion.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchCreateQuestion.fulfilled, (state: any, action: any) => {
                state.question = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchCreateQuestion.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchUpdateQuestion.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUpdateQuestion.fulfilled, (state: any, action: any) => {
                state.question = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchUpdateQuestion.rejected, state => {
                state.isLoading = false;
            })
            .addDefaultCase(() => {
            });
    },
});

const convertDataToServer = (data: any) => {
    const ret: any = {
        code: data.code,
        question: data.question,
        options: data.options,
        answer_type: data.answer_type,
        discussion: data.discussion,
        short_discussion: data.short_discussion,
        introduction: data.introduction,
        is_active: data.is_active,
        is_discussion: data.is_discussion,
        is_short_discussion: data.is_short_discussion,
        is_introduction: data.is_introduction,
    };

    return ret;
}

export const { setFilterParams, resetFilters } = questionSlice.actions;

export default questionSlice.reducer;
