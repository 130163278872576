import React, { FC } from 'react';
import Modal from 'react-modal';
import crossIcon from '../../../assets/images/icons/cross_black.png';
import './ModalView.scss'

interface Prop {
    modalIsOpen:boolean;
    closeModal: any;
    title?: string;
    children?: any;
}

const customStyles = {
    content: {
        top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            maxWidth: 560,
            borderRadius: 0,
            border: 'none',
            padding: 0,
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
            zIndex: 9999,
    },
};

const ModalView: FC<Prop> = ({
    modalIsOpen = false,
    closeModal,
    title,
    children,
}) => {

  return (
      <Modal
          isOpen={modalIsOpen}
          appElement={document.getElementById('root') || undefined}
          // onAfterOpen={afterOpenModal}
          // onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
      >
          <div className={"header"}>
              <h2>{title}</h2>
              <button onClick={closeModal} className={"closeBtn"}>
                  <img src={crossIcon} className={"crossIcon"}/>
              </button>
          </div>
          <div className={"content"}>
              {children}
          </div>
      </Modal>
  );
};

export default ModalView;
