import React, {FC, useEffect, useRef} from 'react';
import {Link as RouterLink, useLocation, useNavigate, useParams} from 'react-router-dom';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import {useAppDispatch, useAppSelector} from "../../../hooks/global";
import {fetchPage, fetchUpdatePage, fetchCreatePage, resetPage} from "../../../store/page/pageSlice";
import {fetchCategoryOptions} from "../../../store/category/categorySlice";
import {fetchFileUpload, resetUploader} from "../../../store/general/generalSlice";
import HeaderView from '../../common/Header/HeaderView';
import FooterView from '../../common/Footer/FooterView';
import TopBanner from '../../common/TopBanner/TopBanner';
import Spinner from "../../common/Spinner/Spinner";
import {Input, Select, TinyMceEditor} from "../../controls";
import {useForm, useWatch} from "react-hook-form";
import FileUploader from "../../controls/FileUploader/FileUploader";
import './Page.scss';

const statusOptions = [
    {value: '1', label: 'Active'},
    {value: '0', label: 'Archived'},
]

const typeOptions = [
    {value: 'document', label: 'Document (pdf/doc/docx)'},
    {value: 'page', label: 'Page'},
]

const Page: FC = () => {

    const {id} = useParams();
    const navigation = useNavigate();
    const dispatch = useAppDispatch();
    const editorRef = useRef(null);

    const {page, isLoading} = useAppSelector(state => state.page);
    const {options} = useAppSelector(state => state.category);
    const {uploader, isLoading: isUploaderLoading} = useAppSelector(state => state.general);

    const {control, handleSubmit, reset, setValue, setError, clearErrors, getValues, formState: {errors}} = useForm({
        mode: 'onBlur',
        defaultValues: {
            alias: '',
            title: '',
            category_id: '',
            type: 'document',
            content: '',
            is_active: '1',
            file_path: '',
            full_path: '',
            file_title: '',
        }
    });

    const content = useWatch({
        control,
        name: 'content',
    });

    const type = useWatch({
        control,
        name: 'type',
    });

    useEffect(() => {
        reset({
            alias: page?.alias || '',
            title: page?.title || '',
            category_id: page?.category_id || '',
            is_active: page ? (page?.is_active ? '1' : '0') : '1',
            type: page?.type || 'document',
            content: page?.content || '',
            file_path: page?.document?.file || '',
            full_path: page?.document?.file || '',
            file_title: page?.document?.title || '',
        });
    }, [page]);

    useEffect(() => {
        dispatch(fetchCategoryOptions({}));
        dispatch(resetUploader());
        dispatch(resetPage());
        if (id) dispatch(fetchPage(id));
    }, [id]);

    useEffect(() => {
        clearErrors();
        dispatch(resetUploader());
    }, [type]);

    useEffect(() => {
        if (uploader && uploader?.file) {
            setValue('file_path', uploader.file.filePath)
            setValue('full_path', uploader.file.fullpath)
            setValue('file_title', uploader.file.original_name)
        }

    }, [uploader]);

    const navToList = () => {
        navigation('/pages', {state: {keepFilters: true}});
    }

    const handleChangeEditor = () => {
        if (editorRef.current) {
            // @ts-ignore
            setValue('content', editorRef.current?.getContent());
        }
    }

    const handleOnSubmit = (data: any) => {
        if (type === 'document' && !getValues('full_path')) {
            setError('file_path', { type: 'custom', message: 'Document is required' });
        } else {
            if (id) {
                dispatch(fetchUpdatePage({id, data: {...data, ...uploader}})).then(resp => {
                    if (resp?.type === 'page/update/fulfilled') navToList();
                });
            } else {
                dispatch(fetchCreatePage({data: {...data, ...uploader}})).then(resp => {
                    if (resp?.type === 'page/create/fulfilled' && resp?.payload?.id) {
                        navigation(`/page/${resp.payload.id}`, {state: {keepFilters: true}});
                    }
                });
            }
        }
    };

    const handleLoadDoc = (e: any) => {
        const file = e.target.files[0] || null;
        if (file) {
            dispatch(fetchFileUpload(file)).then(resp => {
                if (resp?.type === 'file/upload/fulfilled') {
                    clearErrors('file_path');
                    console.log('Uploaded!')
                }
            });
        }
    }

    console.log('errors', errors);

    return (
        <div className="contentWrapper">
            <HeaderView/>
            <Spinner isLoading={isLoading || isUploaderLoading}/>
            <div className="pageContainer">
                <TopBanner/>
                <div className="pageContainer__view">
                    <div className="pageHeader">
                        <div className="pageHeader__rowTitle">
                            <ul className="pageHeader__tabList">
                                <li className="pageHeader__tabItem state_active">
                                    <Link
                                        underline="hover"
                                        component={RouterLink}
                                        className="pageHeader__tabLink"
                                        to={''}
                                    >
                                        Page Details
                                    </Link>
                                </li>
                            </ul>
                            <Button
                                onClick={() => navigation('/pages', {state: {keepFilters: true}})}
                                variant="contained"
                                className="pageHeader__btn -desktop"
                            >
                                <span className="pageHeader__btnIcon -back"/>
                                Back
                            </Button>
                        </div>
                        <Button
                            onClick={() => navigation('/pages', {state: {keepFilters: true}})}
                            variant="contained"
                            className="pageHeader__btn -mobile"
                        >
                            <span className="pageHeader__btnIcon -back"/>
                            Back
                        </Button>
                    </div>
                    <form className="formContainer">
                        <div className="formContainer__row -grid-2">
                            <Input
                                control={control}
                                name={'title'}
                                title={'Title'}
                                placeholder={'Title'}
                                rules={{
                                    required: "This field is required",
                                }}
                                inputProps={{maxLength: 255}}
                            />
                            <Input
                                control={control}
                                name={'alias'}
                                title={'Alias'}
                                disabled={!id}
                                placeholder={'Alias'}
                                rules={id ? {
                                    required: "This field is required",
                                } : {}}
                                inputProps={{maxLength: 255}}
                            />
                        </div>
                        <div className="formContainer__row -grid-2">
                            <Select
                                control={control}
                                className={'selectField__maxWidth600'}
                                name={'category_id'}
                                title={'Category'}
                                options={options}
                                placeholder={'--- Select One ---'}
                                showErrorMessage={false}
                                rules={{
                                    required: "This field is required",
                                }}
                            />
                            <Select
                                control={control}
                                name={'is_active'}
                                title={'Status'}
                                options={statusOptions}
                                showErrorMessage={false}
                                rules={{
                                    required: "This field is required",
                                }}
                            />
                        </div>
                        <div className="formContainer__row -grid-2">
                            <Select
                                control={control}
                                name={'type'}
                                title={'Type'}
                                options={typeOptions}
                                showErrorMessage={false}
                                rules={{
                                    required: "This field is required",
                                }}
                            />
                        </div>
                        {type === 'page' ? (
                                <div className="formContainer__row -grid-1">
                                    <TinyMceEditor
                                        editorRef={editorRef}
                                        value={content}
                                        onChange={handleChangeEditor}
                                    />
                                </div>
                            ) : (
                            <div className="formContainer__row -grid-2">
                                <FileUploader
                                    title={'Document'}
                                    onChange={handleLoadDoc}
                                    filePath={getValues('full_path')}
                                    fileTitle={getValues('file_title')}
                                    isError={Object.prototype.hasOwnProperty.call(errors, "file_path")}
                                    accept={"application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"}
                                />
                            </div>
                        )}
                        <Button
                            onClick={handleSubmit((data) => handleOnSubmit(data))}
                            variant="contained"
                            className="simpleBtn"
                            disabled={isLoading || isUploaderLoading}
                        >
                            Save
                        </Button>
                    </form>
                </div>
            </div>
            <FooterView/>
        </div>
    );
};

export default Page;
