import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import fileDownload from 'js-file-download'
import FileSaver from 'file-saver';
import api from '../../service/api';

export const fetchAffiliates = createAsyncThunk('affiliate/list', async (params: any) => {
    const response = await api.get('admin/affiliates/list', { params });
    return response.data;
});

export const fetchAffiliatesRequests = createAsyncThunk('affiliate/requests', async (params: any) => {
    const response = await api.get('admin/affiliates/requests-list', { params });
    return response.data;
});

export const fetchAffiliate = createAsyncThunk('affiliate/get', async (id: string) => {
    const response = await api.get(`admin/affiliates/${id}/get`);
    return response.data.resource;
});

export const fetchReferrals = createAsyncThunk('affiliate/referrals', async (params: any) => {
    const {id, year} = params;
    const response = await api.get(`admin/affiliates/${id}/referrals?year=${year}`);
    return response.data.resource;
});

export const fetchReferralsReport = createAsyncThunk('affiliate/referrals-report', async (params: any) => {
    const {id, year} = params;
    return await api.get(`admin/affiliates/${id}/referrals-report?year=${year}`, {responseType: "blob"});
});

export const fetchAffiliateRequestsReport = createAsyncThunk('affiliate/referrals-requests-report', async (params: any) => {
    const {affiliate, year} = params;
    return await api.get(`admin/affiliates/referrals-report-export?affiliate=${affiliate}&year=${year}`, {responseType: "blob"});
});

export const fetchUpdateAffiliate = createAsyncThunk('affiliate/update', async (params: any, {
    rejectWithValue,
    dispatch
}) => {
    const {id, data} = params;
    const response = await api.post(`admin/affiliates/${id}/update`, convertDataToServer(data));
    return response.data?.resource;
});

export const fetchCreateAffiliate = createAsyncThunk('affiliate/create', async (params: any, {
    rejectWithValue,
    dispatch
}) => {
    const response = await api.post('admin/affiliates/create', convertDataToServer(params));
    return response.data?.resource;
});

export const fetchMakeReferralPaid = createAsyncThunk('affiliate/make-referral-paid', async (params: any, {
    rejectWithValue,
    dispatch
}) => {
    const {id, items} = params;
    const response = await api.post(`admin/affiliates/${id}/make-referral-paid`, items);
    return response.data?.resource;
});


interface  ListFilters {
    order_by: string,
    order_dir: string,
    page: number,
    ipp: number,
    first_name?: string,
    last_name?: string,
    company?: string,
    phone?: string,
    email?: string,
    is_active?: number,
    affiliate?: string,
    year?: string,
}

interface  Pagination {
    total: number,
    current_page: number,
    last_page: number,
    per_page: number,
    from: number,
    to: number,
}

interface AffiliateState {
    affiliates: Array<any>;
    requests: Array<any>;
    referrals: Array<any>;
    affiliate: any;
    listFilters: ListFilters;
    pagination: Pagination;
    isLoading: boolean;
}

const initialState: AffiliateState = {
    affiliates: [],
    requests: [],
    referrals: [],
    affiliate: null,
    listFilters: {
        order_by: 'first_name',
        order_dir: 'asc',
        page: 1,
        ipp: 25,
        first_name: '',
        last_name: '',
        company: '',
        phone: '',
        email: '',
        affiliate: '',
        year: '',
        is_active: 1,
    },
    pagination: {
        total: 0,
        current_page: 1,
        last_page: 1,
        per_page: 1,
        from: 1,
        to: 1,
    },
    isLoading: false,
};

export const affiliateSlice = createSlice({
    name: 'affiliate',
    initialState,
    reducers: {
        setFilterParams: (state, action) => {
            state.listFilters = action.payload;
        },
        resetFilters: (state) => {
            state.listFilters = initialState.listFilters;
        },
        setAffiliate: (state, action) => {
            state.affiliate = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchAffiliates.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAffiliates.fulfilled, (state: any, action: any) => {
                state.affiliates = action.payload.resource.data;
                state.pagination = {
                    ...state.pagination,
                    current_page: action.payload.resource.current_page,
                    total: action.payload.resource.total,
                    last_page: action.payload.resource.last_page,
                    ipp: action.payload.resource.ipp,
                    page: action.payload.resource.current_page,
                    per_page: action.payload.resource.per_page,
                    from: action.payload.resource.from,
                    to: action.payload.resource.to,
                }
                state.isLoading = false;
            })
            .addCase(fetchAffiliates.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchAffiliatesRequests.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAffiliatesRequests.fulfilled, (state: any, action: any) => {
                state.requests = action.payload.resource.data;
                state.pagination = {
                    ...state.pagination,
                    current_page: action.payload.resource.current_page,
                    total: action.payload.resource.total,
                    last_page: action.payload.resource.last_page,
                    ipp: action.payload.resource.ipp,
                    page: action.payload.resource.current_page,
                    per_page: action.payload.resource.per_page,
                    from: action.payload.resource.from,
                    to: action.payload.resource.to,
                }
                state.isLoading = false;
            })
            .addCase(fetchAffiliatesRequests.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchAffiliate.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAffiliate.fulfilled, (state: any, action: any) => {
                state.affiliate = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchAffiliate.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchReferrals.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchReferrals.fulfilled, (state: any, action: any) => {
                state.affiliate = action.payload?.affiliate;
                state.referrals = action.payload?.referrals || [];
                state.isLoading = false;
            })
            .addCase(fetchReferrals.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchReferralsReport.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchReferralsReport.fulfilled, (state: any, action: any) => {
                state.isLoading = false;
                FileSaver.saveAs(action.payload, "affiliate_report.xlsx");
                //fileDownload(action.payload, 'affiliate_report.xlsx');
            })
            .addCase(fetchReferralsReport.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchAffiliateRequestsReport.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAffiliateRequestsReport.fulfilled, (state: any, action: any) => {
                state.isLoading = false;
                FileSaver.saveAs(action.payload, "affiliate_referral_report.xlsx");
            })
            .addCase(fetchAffiliateRequestsReport.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchUpdateAffiliate.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUpdateAffiliate.fulfilled, (state: any, action: any) => {
                state.affiliate = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchUpdateAffiliate.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchCreateAffiliate.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchCreateAffiliate.fulfilled, (state: any, action: any) => {
                state.affiliate = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchCreateAffiliate.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchMakeReferralPaid.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchMakeReferralPaid.fulfilled, (state: any, action: any) => {
                state.referrals = action.payload || [];
                state.isLoading = false;
            })
            .addCase(fetchMakeReferralPaid.rejected, state => {
                state.isLoading = false;
            })
            .addDefaultCase(() => {
            });
    },
});

const convertDataToServer = (data: any) => {
    const ret: any = {
        user: {
            email: data.email,
            is_active: data.is_active,
        },
        profile: {
            first_name: data.first_name,
            last_name: data.last_name,
            company: data.company,
            notes: data.notes,
            non_reg_price: data.non_reg_price,
            dot_fmcsa_price: data.dot_fmcsa_price,
            non_reg_dot_fmcsa_price: data.non_reg_dot_fmcsa_price,
            commission_type: data.commission_type,
            commission_value: data.commission_value,
        },
        address: {
            phone: data.phone,
        },
    };

    return ret;
}

export const { setFilterParams, resetFilters, setAffiliate } = affiliateSlice.actions;

export default affiliateSlice.reducer;
