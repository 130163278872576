import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from '../../service/api';

export const fetchCategories = createAsyncThunk('category/list', async (params: any) => {
    const response = await api.get('admin/category/list', { params });
    return response.data;
});

export const fetchCategoryOptions = createAsyncThunk('category/options', async (params: any) => {
    const response = await api.get('admin/category/options', { params });
    return response.data.resource;
});

export const fetchCategory = createAsyncThunk('category/get', async (id: string) => {
    const response = await api.get(`admin/category/${id}/get`);
    return response.data.resource;
});

export const fetchCreateCategory = createAsyncThunk('category/create', async (params: any) => {
    const {data} = params;
    const response = await api.post('admin/category/create', convertDataToServer(data));
    return response.data?.resource;
});

export const fetchUpdateCategory = createAsyncThunk('category/update', async (params: any) => {
    const {id, data} = params;
    const response = await api.post(`admin/category/${id}/update`, convertDataToServer(data));
    return response.data?.resource;
});

export const fetchDeleteCategory = createAsyncThunk('category/delete', async (params: any) => {
    const {id} = params;
    const response = await api.post(`admin/category/${id}/delete`);
    return response.data?.resource;
});

interface  ListFilters {
    order_by: string,
    order_dir: string,
    page: number,
    ipp: number,
    category_id: number | null,
    title: string,
    is_active: string,
}

interface  Pagination {
    total: number,
    current_page: number,
    last_page: number,
    per_page: number,
    from: number,
    to: number,
}

interface CategoryState {
    categories: Array<object>;
    options: Array<{alias: string, value: string, label: string}>;
    parentsOptions: Array<{alias: string, value: string, label: string}>;
    category: any;
    listFilters: ListFilters;
    pagination: Pagination;
    isLoading: boolean;
}

const initialState: CategoryState = {
    categories: [],
    options: [],
    parentsOptions: [],
    category: null,
    listFilters: {
        order_by: 'id',
        order_dir: 'asc',
        page: 1,
        ipp: 25,
        category_id: null,
        title: '',
        is_active: '',
    },
    pagination: {
        total: 0,
        current_page: 1,
        last_page: 1,
        per_page: 1,
        from: 1,
        to: 1,
    },
    isLoading: false,
};

export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        setFilterParams: (state, action) => {
            state.listFilters = action.payload;
        },
        resetFilters: (state) => {
            state.listFilters = initialState.listFilters;
        },
        resetCategory: (state) => {
            state.category = initialState.category;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchCategories.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchCategories.fulfilled, (state: any, action: any) => {
                state.categories = action.payload.resource.data;
                state.pagination = {
                    ...state.pagination,
                    current_page: action.payload.resource.current_page,
                    total: action.payload.resource.total,
                    last_page: action.payload.resource.last_page,
                    ipp: action.payload.resource.ipp,
                    page: action.payload.resource.current_page,
                    per_page: action.payload.resource.per_page,
                    from: action.payload.resource.from,
                    to: action.payload.resource.to,
                }
                state.isLoading = false;
            })
            .addCase(fetchCategories.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchCategoryOptions.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchCategoryOptions.fulfilled, (state: any, action: any) => {
                state.options = prepareOptions(action.payload);
                state.parentsOptions = prepareParentOptions(action.payload);
                state.isLoading = false;
            })
            .addCase(fetchCategoryOptions.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchCategory.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchCategory.fulfilled, (state: any, action: any) => {
                state.category = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchCategory.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchCreateCategory.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchCreateCategory.fulfilled, (state: any, action: any) => {
                state.category = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchCreateCategory.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchUpdateCategory.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUpdateCategory.fulfilled, (state: any, action: any) => {
                state.category = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchUpdateCategory.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchDeleteCategory.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchDeleteCategory.fulfilled, (state: any, action: any) => {
                state.isLoading = false;
            })
            .addCase(fetchDeleteCategory.rejected, state => {
                state.isLoading = false;
            })
            .addDefaultCase(state => {
                state.isLoading = false;
            });
    },
});

const convertDataToServer = (data: any) => {
    const ret: any = {
        position: data.position,
        alias: data.alias,
        title: data.title,
        parent_id: data.parent_id,
        content: data.content,
        is_active: data.is_active,
    };

    return ret;
}

const prepareOptions = (data: any) => {

    const handleChildren = (items: any, parent:any = null) => {
        const options:any = [];

        items.forEach((item:any) => {
            const row = {alias: item.alias, value: item.id, label: ((parent ? parent?.label+' --- ' : '')+item.title)};
            //const row = {alias: item.alias, value: item.id, label: ((parent ? (parent.label!=='Root' ? (parent?.label+' --- ') : '') : '')+item.title)};
            options.push(row);
            if (item.childrens) {
                const ret = handleChildren(item.childrens, row);
                if (ret) options.push(...ret);
            }
        });

        return options
    }

    return handleChildren(data, null);
}

const prepareParentOptions = (data: any) => {

    const handleChildren = (items: any, parent:any = null, level: number) => {
        const deep = 3;
        const options:any = [];

        if (deep && level >= deep) return;

        items.forEach((item:any) => {
            const row = {alias: item.alias, value: item.id, label: ((parent ? parent?.label+' --- ' : '')+item.title)};
            // const row = {value: item.id, label: ((parent ? (parent.label!=='Root' ? (parent?.label+' --- ') : '') : '')+item.title)};
            options.push(row);
            if (item.childrens) {
                const ret = handleChildren(item.childrens, row, level+1);
                if (ret) options.push(...ret);
            }
        });

        return options
    }

    return handleChildren(data, null, 1);
}

// const rootSort = (data: any) => {
//     const root = data.find((item: any)=>item.label==='Root');
//     if (root) {
//         data.splice(data.indexOf(root), 1);
//         data.unshift(root);
//     }
//     return data;
// }

export const { setFilterParams, resetFilters, resetCategory } = categorySlice.actions;

export default categorySlice.reducer;
