import React, {FC, useEffect, useState} from 'react';
import {Controller} from "react-hook-form";
import {convertToRaw, EditorState, ContentState} from 'draft-js';
import draftToHtml from "draftjs-to-html";
import {Editor} from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './DraftTextarea.scss';

interface Props {
    defaultValue?: string;
    control?: any;
    name?: string;
    readOnly?: boolean;
}

const DraftTextarea: FC<Props> = ({defaultValue, control, name, readOnly = false}) => {
    const [editorState, setEditorState] = useState<any>();
    const onEditorStateChange = (editorState: any) => {
        setEditorState(editorState)
    };

    useEffect(() => {
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(defaultValue || "").contentBlocks)));
    }, [defaultValue]);

    return editorState ? (
        <Controller
            name={name || ''}
            control={control}
            render={({field, fieldState: {error}}) =>
                <Editor
                    {...field}
                    onChange={(e) => {
                        field.onChange(
                            draftToHtml(convertToRaw(editorState.getCurrentContent()))
                        );
                    }}
                    editorState={editorState}
                    editorClassName={readOnly ? "draftEditorCustomReadonly" : "draftEditorCustom"}
                    onEditorStateChange={onEditorStateChange}
                    readOnly={readOnly}
                    toolbarHidden={readOnly}
                    toolbar={{
                        options: [
                            'inline',
                            'fontSize',
                            'fontFamily',
                            'textAlign',
                            'colorPicker',
                            'link',
                            'remove',
                            'history',
                        ],
                        inline: {
                            inDropdown: false,
                            options: ['bold', 'italic', 'underline'],
                        },
                    }}
                />
            }
        />
    ) : null;
};

export default DraftTextarea;
