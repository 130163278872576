import React, { FC } from 'react';
import './InputField.scss'
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';

interface Props {
  title: string;
  value?: string;
  placeholder: string;
  disabled?: boolean;
  readOnly?: boolean;
  isError?: boolean;
}

const InputField: FC<Props> = ({
    title,
    value,
    placeholder,
    disabled= false,
    readOnly= false,
    isError= false,
}) => {

  return (
      <div>
        <FormControl className="inputField">
          <div className="inputField__title">{title}</div>
          <Input
              className={`inputField__input ${isError ? 'error' : ''}`}
              placeholder={placeholder}
              disabled={disabled}
              readOnly={readOnly}
              value={value}
          />
        </FormControl>
      </div>
  );
};

export default InputField;
