import React, { FC } from 'react';
import moment from "moment";
import { TableBody, TableContainer, TableHead, TableRow, Table } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {formatter} from '../AffiliateReport';
import './Table.scss';

const TableComponent: FC<any> = ({affiliate}) => {

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#fff',
      color: '#14213D',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(even)': {
      backgroundColor: '#FDFDFD',
    },
    '& td, th': {
      border: 0,
    },
  }));

  const StyledTableHeadRow = styled(TableRow)(() => ({
    '&.MuiTableRow-root.MuiTableRow-head': {
      borderTop: '1px solid #F2F1EF',
    },
  }));

  const StyledTableContainer = styled(TableContainer)(() => ({
    '&.MuiTableContainer-root': {
      borderRadius: '0px',
    },
  }));

  return (
    <div className="table -reportInfo">
      <StyledTableContainer className="tableContainer">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <StyledTableHeadRow>
              <StyledTableCell>Commission</StyledTableCell>
              <StyledTableCell align="right">Amount</StyledTableCell>
            </StyledTableHeadRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                Referral Commission to be Paid <span> / YTD: {`${moment(affiliate?.commissions.dateMin).format('MM/DD/YYYY')} - ${moment(affiliate?.commissions.dateMax).format('MM/DD/YYYY')}`}</span>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row" align="right">
                {formatter.format(affiliate?.commissions.toBePaid || 0)}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                Total Referral Commission Paid <span> / YTD: {`${moment(affiliate?.commissions.dateMin).format('MM/DD/YYYY')} - ${moment(affiliate?.commissions.dateMax).format('MM/DD/YYYY')}`}</span>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row" align="right">
                {formatter.format(affiliate?.commissions.paid || 0)}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                Total Referral Commission<span> / YTD: {`${moment(affiliate?.commissions.dateMin).format('MM/DD/YYYY')} - ${moment(affiliate?.commissions.dateMax).format('MM/DD/YYYY')}`}</span>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row" align="right">
                {formatter.format(affiliate?.commissions.total || 0)}
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </StyledTableContainer>
    </div>
  );
};

export default TableComponent;
