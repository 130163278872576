import React, { FC } from 'react';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import Button from "@mui/material/Button";
import './FileUploader.scss'

interface Props {
  title: string;
  filePath?: string;
  fileTitle?: string;
  placeholder?: string;
  accept?: string | undefined;
  disabled?: boolean;
  readOnly?: boolean;
  type?: string;
  onChange?: any;
  isError?: boolean;
  hidden?: any;
}

const FileUploader: FC<Props> = ({
    title,
    filePath,
    fileTitle,
    placeholder,
    disabled= false,
    readOnly= false,
    isError= false,
    type= 'input',
    onChange = null,
    accept = undefined,
    ...props
}) => {

  const getTitle = (title: any) => {
      if (title) {
          //TODO place ... before file extension
          return (title.length > 40) ? (title.substring(0, 40)+'...') : title;
      }
      return title;
  }

  return (
      <div>
        <FormControl className="UploaderInputField">
          <div className="UploaderInputField__title">{title}</div>
          <div className={'uploader'}>
              <Input
                  className={`UploaderInputField__input ${isError ? 'error' : ''}`}
                  placeholder={placeholder}
                  disabled={disabled}
                  readOnly={readOnly}
                  onChange={onChange}
                  startAdornment={
                      <a className={'uploaderLink'} href={filePath} target={'_blank'} rel="noreferrer">{getTitle(fileTitle)}</a>
                  }
              />
              <Button variant="contained" component="label" className="uploadButton">
                  Upload
                  <input
                      style={{display: 'none'}}
                      type={'file'}
                      onChange={onChange}
                      accept={accept}
                  />
              </Button>
          </div>
        </FormControl>
      </div>
  );
};

export default FileUploader;
