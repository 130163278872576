import React, {FC, useEffect, useRef, useState} from 'react';
import {useForm} from "react-hook-form";
import {Link as RouterLink, useLocation, useNavigate} from "react-router-dom";
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import {useAppDispatch, useAppSelector} from '../../../hooks/global';
import {fetchCustomers, setFilterParams, resetFilters} from "../../../store/customer/customerSlice";
import {fetchStates} from "../../../store/general/generalSlice";
import {ROUTES} from "../../../router/routes";
import HeaderView from '../../common/Header/HeaderView'
import FooterView from '../../common/Footer/FooterView'
import TopBanner from '../../common/TopBanner/TopBanner'
import DataTableView from "../../common/DataTable/DataTableView";
import {Input, Select, Autocomplete} from "../../controls";
import Spinner from "../../common/Spinner/Spinner";
import './Customers.scss'

const columns = [
    {
        name: 'first name',
        alias: 'first_name',
        selector: (row: any) => row.first_name,
        sortable: true,
        width: '140px',
    },
    {
        name: 'Last Name',
        alias: 'last_name',
        selector: (row: any) => row.last_name,
        sortable: true,
        width: '140px',
    },
    {
        name: 'Company Name',
        alias: 'company',
        selector: (row: any) => row.company,
        sortable: true,
    },
    {
        name: 'State',
        alias: 'state',
        selector: (row: any) => row.state,
        sortable: true,
        width: '90px',
    },
    {
        name: 'phone Number',
        alias: 'phone',
        selector: (row: any) => row.phone,
        sortable: true,
        width: '160px',
    },
    {
        name: 'Email Address',
        alias: 'email',
        selector: (row: any) => row.email,
        sortable: true,
        width: '250px',
        cell: (row: any) => <Link
            component={RouterLink}
            className="email"
            to={''}
        >
            {row.email}
        </Link>
    },
    {
        name: 'Status',
        alias: 'is_active',
        selector: (row: any) => row.is_active,
        sortable: true,
        width: '120px',
        cell: (row: any) =>
            <span className={`status 
        ${row.is_active === 1 ? '-active' : ''}
        ${row.is_active === 0 ? '-archived' : ''}`}>
          {row.is_active ? 'Active' : 'Archived'}
          </span>
    },
    {
        name: 'Edit',
        alias: 'edit',
        selector: (row: any) => null,
        sortable: false,
        width: '60px',
        cell: (row: any) => <Link
            component={RouterLink}
            className="editLink"
            to={`/customer/${row.id}`}
        >
            <span className="editLink__icon"/>
        </Link>
    },
];

const statusOptions = [
    {value: '1', label: 'Active'},
    {value: '0', label: 'Archived'},
]

const Customers: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const didMountRef = useRef(false);
    const {customers, listFilters, pagination, isLoading} = useAppSelector(state => state.customer);
    const {states} = useAppSelector(state => state.general);
    const [statesOptions, setStatesOptions] = useState([{value: '', label: ''}]);
    const keepFilters = location.state?.keepFilters || false;
    const {control, handleSubmit, reset } = useForm({
        mode: 'onBlur',
        defaultValues: {
            first_name: listFilters.first_name,
            last_name: listFilters.last_name,
            company: listFilters.company,
            phone: listFilters.phone,
            email: listFilters.email,
            state_id: listFilters.state_id,
            is_active: listFilters.is_active,
        }
    });

    useEffect(() => {
        didMountRef.current = true;
        if (!keepFilters) handleReset();
        dispatch(fetchStates());
    }, []);

    useEffect(() => {
        if (didMountRef.current) {
            dispatch(fetchCustomers(listFilters));
        }
    }, [listFilters]);

    const handleSearch = (data: any) => {
        dispatch(setFilterParams({...listFilters, ...data, page: 1}));
    };

    const handleReset = () => {
        const data = {
            first_name: '',
            last_name: '',
            company: '',
            phone: '',
            email: '',
            state_id: '',
            is_active: 1,
        };
        reset(data);
        dispatch(setFilterParams({...listFilters, ...data, page: 1}));
    };

    const handleChangePage = (page: number) => {
        dispatch(setFilterParams({...listFilters, page}));
    };

    const handleSort = (order_by: string, order_dir: string) => {
        dispatch(setFilterParams({...listFilters, order_by, order_dir, page: 1}));
    };

    useEffect(() => {
        setStatesOptions(states.map((state) => ({
            value: state.id,
            label: state.code,
        })))
    }, [states]);

    return (
        <div className="contentWrapper">
            <HeaderView/>
            <Spinner isLoading={isLoading}/>
            <div className="pageContainer">
                <TopBanner/>
                <div className="pageContainer__view">
                    <div className="pageHeader">
                        <div className="pageHeader__rowTitle">
                            <h2 className="pageHeader__title">Customers</h2>
                            <Button
                                onClick={() => navigate(ROUTES.CUSTOMER_CREATE)}
                                variant="contained"
                                className="pageHeader__btn">
                                <span className="pageHeader__btnIcon -plus"></span>
                                Add New Customer
                            </Button>
                        </div>
                    </div>
                    <form className="formContainer">
                        <div className="formContainer__row -grid-4">
                            <Input
                                control={control}
                                name={'first_name'}
                                title={'first name'}
                                placeholder={'Enter First Name'}
                            />
                            <Input
                                control={control}
                                name={'last_name'}
                                title={'Last name'}
                                placeholder={'Enter Last Name'}
                            />
                            <Input
                                control={control}
                                name={'company'}
                                title={'Company name'}
                                placeholder={'Enter Company Name'}
                            />
                            <Autocomplete
                                control={control}
                                name={'state_id'}
                                title={'State'}
                                options={statesOptions}
                                isMatchFromStart={true}
                                placeholder={'Select One'}
                            />
                            <Input
                                control={control}
                                name={'phone'}
                                title={'Phone Number'}
                                placeholder={'Enter Phone Number'}
                            />
                            <Input
                                control={control}
                                name={'email'}
                                title={'Email Address'}
                                placeholder={'Enter Email Address'}
                            />
                            <Select
                                control={control}
                                name={'is_active'}
                                title={'Customer Status'}
                                options={statusOptions}
                            />
                        </div>
                        <Button
                            onClick={handleSubmit(handleSearch)}
                            type={"submit"}
                            variant="contained"
                            className="simpleBtn -blue">
                            search
                        </Button>
                        <Button
                            onClick={handleReset}
                            variant="contained"
                            className="simpleBtn -ml-10">
                            Reset
                        </Button>
                    </form>
                    <div className="tableList -customer">
                        <h3 className="tableList__title">Search Results</h3>
                        <DataTableView
                            data={customers}
                            columns={columns}
                            currentPage={pagination.current_page}
                            paginationTotalRows={pagination.total}
                            paginationPerPage={pagination.per_page}
                            lastPage={pagination.last_page}
                            from={pagination.from}
                            to={pagination.to}
                            onChangePage={(page) => handleChangePage(page)}
                            onSort={handleSort}
                        />
                    </div>
                </div>
            </div>
            <FooterView/>
        </div>
    );
};

export default Customers;
