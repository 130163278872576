import React, {FC, useEffect, useState} from 'react';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import {useAppDispatch, useAppSelector} from "../../../hooks/global";
import {fetchAffiliate, fetchUpdateAffiliate} from "../../../store/affiliate/affiliateSlice";
import HeaderView from '../../common/Header/HeaderView';
import FooterView from '../../common/Footer/FooterView';
import TopBanner from '../../common/TopBanner/TopBanner';
import {AffiliateForm} from "../../common";
import Spinner from "../../common/Spinner/Spinner";

const AffiliateEdit: FC = () => {
    const {id} = useParams();
    const navigation = useNavigate();
    const dispatch = useAppDispatch();
    const {affiliate, isLoading} = useAppSelector(state => state.affiliate);

    useEffect(() => {
        if (id) dispatch(fetchAffiliate(id));
    }, [id]);

    const handleSubmit = (data: any) => {
        dispatch(fetchUpdateAffiliate({id, data})).then(resp => {
            if (resp?.type === 'affiliate/update/fulfilled') {
                navigation('/affiliates', {state: {keepFilters: true}});
            }
        });
    };

    const getAffiliateName = (affiliate: any): string => {
        return `${affiliate?.profile.first_name || ''} ${affiliate?.profile.last_name || ''}`
    }

    const getAffiliateCompany = (affiliate: any): string => {
        return `${affiliate?.profile.company || ''}`
    }

    return (
        <div className="contentWrapper">
            <HeaderView/>
            <Spinner isLoading={isLoading}/>
            <div className="pageContainer">
                <TopBanner/>
                <div className="pageContainer__view">
                    <div className="pageHeader">
                        <div className="pageHeader__rowTitle">
                            <ul className="pageHeader__tabList">
                                <li className="pageHeader__tabItem state_active">
                                    <Link
                                        underline="hover"
                                        component={RouterLink}
                                        className="pageHeader__tabLink"
                                        to={''}
                                    >
                                        Channel Partner Details
                                    </Link>
                                </li>
                                <li className="pageHeader__tabItem">
                                    <Link
                                        underline="hover"
                                        component={RouterLink}
                                        className="pageHeader__tabLink"
                                        to={`/affiliate/${id}/report`}
                                    >
                                        Channel Partner Referral Report
                                    </Link>
                                </li>
                            </ul>
                            <Button
                                onClick={() => navigation('/affiliates', {state: {keepFilters: true}})}
                                variant="contained"
                                className="pageHeader__btn -desktop"
                            >
                                <span className="pageHeader__btnIcon -back"></span>
                                Back
                            </Button>
                        </div>
                        <div className="pageHeader__rowSubTitle">
                            <span>{getAffiliateName(affiliate)}</span> | <span>{getAffiliateCompany(affiliate)}</span>
                        </div>
                        <Button
                            onClick={() => navigation('/affiliates', {state: {keepFilters: true}})}
                            variant="contained"
                            className="pageHeader__btn -mobile"
                        >
                            <span className="pageHeader__btnIcon -back"></span>
                            Back
                        </Button>
                    </div>
                    <AffiliateForm
                        affiliate={affiliate}
                        handleOnSubmit={handleSubmit}
                    />
                </div>
            </div>
            <FooterView/>
        </div>
    );
};

export default AffiliateEdit;
