export enum ROUTES {
  HOME = '/',
  SIGN_IN = '/sign-in',
  FORGOT_PASSWORD = '/forgot-password',
  SET_PASSWORD = '/set-password',
  CUSTOMERS = '/customers',
  CUSTOMER_CREATE = '/customer/create',
  CUSTOMER_EDIT = '/customer/:id',
  CUSTOMER_HISTORY = '/customer/:id/history',
  AFFILIATES = '/affiliates',
  AFFILIATE_CREATE = '/affiliate/create',
  AFFILIATE_EDIT = '/affiliate/:id',
  AFFILIATE_REPORTS = '/affiliate/:id/report',
  AFFILIATE_REQUESTS = '/affiliates/requests',
  USERS = '/users',
  USER_CREATE = '/user/create',
  USER_EDIT = '/user/:id',
  QUESTIONS = '/questions',
  QUESTION_EDIT = '/question/:id',
  DOCUMENTS = '/documents',
  DOCUMENT_EDIT = '/document/:id',
  CATEGORIES = '/categories',
  CATEGORY_CREATE = '/category/create',
  CATEGORY_EDIT = '/category/:id',
  PAGES = '/pages',
  PAGE_CREATE = '/page/create',
  PAGE_EDIT = '/page/:id',
}
