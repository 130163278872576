import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './store/store';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import './assets/styles/index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
        <ToastContainer
            position="top-center"
            style={{width: "400px"}}
        />
    </Provider>
);
