import React, {FC, useEffect, useRef, useState} from 'react';
import {Link as RouterLink, useLocation, useNavigate, useParams} from 'react-router-dom';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import {useAppDispatch, useAppSelector} from "../../../hooks/global";
import {
    fetchCategory,
    fetchCategoryOptions,
    fetchCreateCategory,
    fetchUpdateCategory,
    resetCategory
} from "../../../store/category/categorySlice";
import HeaderView from '../../common/Header/HeaderView';
import FooterView from '../../common/Footer/FooterView';
import TopBanner from '../../common/TopBanner/TopBanner';
import Spinner from "../../common/Spinner/Spinner";
import {Input, Select, TinyMceEditor} from "../../controls";
import {useForm, useWatch} from "react-hook-form";
import './Category.scss';

const statusOptions = [
    {value: '1', label: 'Active'},
    {value: '0', label: 'Archived'},
]

const Category: FC = () => {

    const {id} = useParams();
    const navigation = useNavigate();
    const dispatch = useAppDispatch();
    const editorRef = useRef(null);
    const {category, parentsOptions, isLoading} = useAppSelector(state => state.category);
    const [categoryOptions, setCategoryOptions] = useState<any>([]);

    const {control, handleSubmit, reset, setValue, formState: {errors}} = useForm({
        mode: 'onBlur',
        defaultValues: {
            alias: '',
            title: '',
            parent_id: '',
            content: '',
            position: 0,
            is_active: '1',
        }
    });

    const content = useWatch({
        control,
        name: 'content',
    });

    useEffect(() => {
        if (parentsOptions) {
            const arr = [{label: 'No Parent ', value: '0'}];
            arr.push(...parentsOptions)
            setCategoryOptions(arr);
        }
    }, [parentsOptions]);

    useEffect(() => {
        reset({
            alias: category?.alias || '',
            title: category?.title || '',
            parent_id: category?.parent_id === null ? '0' : (category?.parent_id || ''),
            position: category?.position || 0,
            is_active: category ? (category?.is_active ? '1' : '0') : '1',
            content: category?.content || '',
        });
    }, [category]);

    useEffect(() => {
        dispatch(fetchCategoryOptions({}));
        dispatch(resetCategory());
        if (id) dispatch(fetchCategory(id));
    }, [id]);

    const navToList = () => {
        navigation('/categories', {state: {keepFilters: true}});
    }

    const handleChangeEditor = () => {
        if (editorRef.current) {
            // @ts-ignore
            setValue('content', editorRef.current?.getContent());
        }
    }

    const handleOnSubmit = (data: any) => {
        if (id) {
            dispatch(fetchUpdateCategory({id, data})).then(resp => {
                if (resp?.type === 'category/update/fulfilled') navToList();
            });
        } else {
            dispatch(fetchCreateCategory({data})).then(resp => {
                if (resp?.type === 'category/create/fulfilled' && resp?.payload?.id) {
                    navigation(`/category/${resp.payload.id}`, {state: {keepFilters: true}});
                }
            });
        }
    };

    console.log('errors', errors);

    return (
        <div className="contentWrapper">
            <HeaderView/>
            <Spinner isLoading={isLoading}/>
            <div className="pageContainer">
                <TopBanner/>
                <div className="pageContainer__view">
                    <div className="pageHeader">
                        <div className="pageHeader__rowTitle">
                            <ul className="pageHeader__tabList">
                                <li className="pageHeader__tabItem state_active">
                                    <Link
                                        underline="hover"
                                        component={RouterLink}
                                        className="pageHeader__tabLink"
                                        to={''}
                                    >
                                        Category Details
                                    </Link>
                                </li>
                            </ul>
                            <Button
                                onClick={() => navigation('/categories', {state: {keepFilters: true}})}
                                variant="contained"
                                className="pageHeader__btn -desktop"
                            >
                                <span className="pageHeader__btnIcon -back"/>
                                Back
                            </Button>
                        </div>
                        <Button
                            onClick={() => navigation('/categories', {state: {keepFilters: true}})}
                            variant="contained"
                            className="pageHeader__btn -mobile"
                        >
                            <span className="pageHeader__btnIcon -back"/>
                            Back
                        </Button>
                    </div>
                    <form className="formContainer">
                        <div className="formContainer__row -grid-2">
                            <Input
                                control={control}
                                name={'title'}
                                title={'Title'}
                                placeholder={'Title'}
                                rules={{
                                    required: "This field is required",
                                }}
                                inputProps={{maxLength: 255}}
                            />
                            <Input
                                control={control}
                                name={'alias'}
                                title={'Alias'}
                                disabled={!id}
                                placeholder={'Alias'}
                                rules={id ? {
                                    required: "This field is required",
                                } : {}}
                                inputProps={{maxLength: 255}}
                            />
                        </div>
                        <div className="formContainer__row -grid-2">
                            <Input
                                control={control}
                                name={'position'}
                                title={'Position'}
                                placeholder={'Position'}
                                rules={{
                                    required: "This field is required",
                                }}
                                inputProps={{maxLength: 50}}
                            />
                            <Select
                                control={control}
                                name={'is_active'}
                                title={'Status'}
                                options={statusOptions}
                                showErrorMessage={false}
                                rules={{
                                    required: "This field is required",
                                }}
                            />
                        </div>
                        <div className="formContainer__row -grid-2">
                            <Select
                                control={control}
                                className={'selectField__maxWidth600'}
                                name={'parent_id'}
                                title={'Parent Category'}
                                options={categoryOptions}
                                placeholder={'--- Select One ---'}
                                showErrorMessage={false}
                                rules={{
                                    required: "This field is required",
                                }}
                            />
                        </div>
                        {/*{false ? (*/}
                        {/*        <div className="formContainer__row -grid-1">*/}
                        {/*            <TinyMceEditor*/}
                        {/*                editorRef={editorRef}*/}
                        {/*                value={content}*/}
                        {/*                onChange={handleChangeEditor}*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    ) : null}*/}
                        <Button
                            onClick={handleSubmit((data) => handleOnSubmit(data))}
                            variant="contained"
                            className="simpleBtn"
                        >
                            Save
                        </Button>
                    </form>
                </div>
            </div>
            <FooterView/>
        </div>
    );
};

export default Category;
