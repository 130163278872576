import React, {FC, useEffect, useState} from 'react';
import {useForm, useWatch} from "react-hook-form";
import Button from '@mui/material/Button';
import {useAppDispatch, useAppSelector} from '../../../hooks/global';
import {fetchStates} from "../../../store/general/generalSlice";
import {fetchAffiliates} from "../../../store/affiliate/affiliateSlice";
import {Input, Select, DraftTextarea, Textarea, Autocomplete, InputField} from "../../controls";
import './CustomerForm.scss';

type Props = {
    customer?: any
    handleOnSubmit: (data: any) => void
};

const statusOptions = [
    {value: '1', label: 'Active'},
    {value: '0', label: 'Archived'},
]

const referredOptions = [
    {value: 'affiliate', label: 'Affiliate'},
    {value: 'search', label: 'Google / Search Engine'},
    {value: 'insurance', label: 'Insurance Provider'},
    {value: 'association', label: 'Group / Association'},
    {value: 'other', label: 'Other'},
]

const CustomerForm: FC<Props> = ({
     customer,
     handleOnSubmit
 }) => {

    const dispatch = useAppDispatch();
    const {states} = useAppSelector(state => state.general);
    const {affiliates} = useAppSelector(customer => customer.affiliate);
    const [statesOptions, setStatesOptions] = useState([{value: '', label: ''}]);
    const [affiliateOptions, setAffiliateOptions] = useState([{value: '', label: ''}]);
    const {control, handleSubmit, reset, setValue, getValues, formState: {errors}} = useForm({
        mode: 'onBlur',
        defaultValues: {
            email: '',
            is_active: '1',
            first_name: '',
            last_name: '',
            company: '',
            notes: '',
            warning_notes: '',
            affiliate_id: '',
            referred_type: 'affiliate',
            referred_value: '',
            state_id: '0',
            address: '',
            address2: '',
            city: '',
            phone: '',
            zip: '',
            new_pwd: '',
        }
    });

    useEffect(() => {
        dispatch(fetchStates());
        dispatch(fetchAffiliates({ipp: 99999, order_by: 'first_name', order_dir: 'asc'}));
    }, []);

    useEffect(() => {
        setStatesOptions(states.map((item) => ({
            value: item.id,
            label: item.code,
        })))
    }, [states]);

    useEffect(() => {
        setAffiliateOptions(affiliates.map((item: any) => ({
            value: item.id,
            label: `${item.first_name} ${item.last_name}`,
        })))
    }, [affiliates]);

    useEffect(() => {
        reset({
            email: customer?.email || '',
            is_active: customer ? (customer?.is_active ? '1' : '0') : '1',
            first_name: customer?.profile.first_name || '',
            last_name: customer?.profile.last_name || '',
            company: customer?.profile.company || '',
            notes: customer?.profile.notes || '',
            warning_notes: customer?.profile.warning_notes || '',
            affiliate_id: customer?.profile.affiliate_id || 0,
            referred_type: customer?.profile.referred_type || 'affiliate',
            referred_value: customer?.profile.referred_value || '',
            state_id: customer?.address.state_id || 0,
            address: customer?.address.address || '',
            address2: customer?.address.address2 || '',
            city: customer?.address.city || '',
            phone: customer?.address.phone || '',
            zip: customer?.address.zip || '',
            new_pwd: '',
        });
    }, [customer]);

    const getReferredValueName = (): string => {
        const option = referredOptions.find(item=>(item.value === referredType));
        return option?.label || '';
    }

    const newPassword = useWatch({
        control,
        name: 'new_pwd',
    });

    const referredType = useWatch({
        control,
        name: 'referred_type',
    });

    useEffect(() => {
        setValue('affiliate_id', '0');
        setValue('referred_value', customer?.profile.referred_value || '');
    }, [referredType]);

    const passwordRules = {
        pattern: {
            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
            message: 'Minimum 8 characters, at least one uppercase and lowercase letter and one number',
        },
        //required: "This field is required",
    }

    return (
        <form className="formContainer">
            <div className="formContainer__row -grid-4">
                <Select
                    control={control}
                    name={'is_active'}
                    title={'Customer Status*'}
                    options={statusOptions}
                />
                <Input
                    control={control}
                    name={'first_name'}
                    title={'first name*'}
                    placeholder={'Enter First Name'}
                    rules={{
                        required: "This field is required",
                    }}
                    inputProps={{maxLength: 50}}
                />
                <Input
                    control={control}
                    name={'last_name'}
                    title={'Last name*'}
                    placeholder={'Enter Last Name'}
                    rules={{
                        required: "This field is required",
                    }}
                    inputProps={{maxLength: 50}}
                />
                <div>
                    <div className="selectField__title">Customer Warning Notes</div>
                    <DraftTextarea
                        control={control}
                        name={'warning_notes'}
                        defaultValue={customer?.profile.warning_notes || ''}
                    />
                </div>
            </div>
            <h3 className="formContainer__title mt-0">Customer Information</h3>
            <div className="formContainer__row">
                <div className="formContainer__row -grid-4 alignTop">
                    <Select
                        control={control}
                        name={'referred_type'}
                        title={'How did you learn about Drug Free Guru?*'}
                        options={referredOptions}
                    />
                    {referredType === 'affiliate' && (
                        <Autocomplete
                            control={control}
                            name={'affiliate_id'}
                            title={'Affiliates*'}
                            options={affiliateOptions}
                            placeholder={'Select One'}
                            showErrorMessage={false}
                            rules={{
                                required: "This field is required",
                                min: {
                                    value: 1,
                                    message: "This field is required"
                                },
                            }}
                        />
                    )}
                    {(referredType === 'association' || referredType === 'other') && (
                        <Input
                            control={control}
                            name={'referred_value'}
                            title={`${getReferredValueName()}*`}
                            rules={{
                                required: "This field is required",
                            }}
                            inputProps={{maxLength: 50}}
                            showErrorMessage={false}
                        />
                    )}
                </div>
            </div>
            <h3 className="formContainer__title">Contact Information</h3>
            <div className="formContainer__row -grid-4">
                <Input
                    control={control}
                    name={'phone'}
                    title={'Phone Number*'}
                    placeholder={'Enter Phone Number'}
                    type={'phone'}
                    rules={{
                        required: "This field is required",
                    }}
                />
                <Input
                    control={control}
                    name={'email'}
                    title={'Email Address*'}
                    placeholder={'Enter Email Address'}
                    rules={{
                        required: "This field is required",
                        pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Invalid Email Address"
                        }
                    }}
                    inputProps={{maxLength: 100}}
                />
                <Input
                    control={control}
                    name={'new_pwd'}
                    title={`Password`}
                    type={'password'}
                    placeholder={'Enter password'}
                    isAutocompleteOff={true}
                    rules={newPassword ? passwordRules : {}}
                    inputProps={{maxLength: 50}}
                />
                {customer?.profile?.id && (
                    <InputField
                        title={'Customer DFG Affiliate Code:'}
                        placeholder={''}
                        value={customer?.profile?.code || ''}
                        readOnly={true}
                    />
                )}
            </div>
            <h3 className="formContainer__title">Company Information</h3>
            <div className="formContainer__row -grid-4">
                <Input
                    control={control}
                    name={'company'}
                    title={'Company Name*'}
                    placeholder={'Enter Company Name'}
                    inputProps={{maxLength: 100}}
                    rules={{
                        required: "This field is required",
                    }}
                />
                <Input
                    control={control}
                    name={'address'}
                    title={'Address 1*'}
                    placeholder={'Enter Address 1'}
                    inputProps={{maxLength: 100}}
                    rules={{
                        required: "This field is required",
                    }}
                />
                <Input
                    control={control}
                    name={'address2'}
                    title={'Address 2'}
                    placeholder={'Enter Address 2'}
                    inputProps={{maxLength: 100}}
                />
            </div>
            <div className="formContainer__row -grid-4">
                <Input
                    control={control}
                    name={'city'}
                    title={'City*'}
                    placeholder={'Enter City'}
                    inputProps={{maxLength: 50}}
                    rules={{
                        required: "This field is required",
                    }}
                />
                <Autocomplete
                    control={control}
                    name={'state_id'}
                    title={'State*'}
                    options={statesOptions}
                    placeholder={'Select One'}
                    isMatchFromStart={true}
                    rules={{
                        validate: {
                            validate: (value: any) => {
                                return (!value || value === '') ? 'This field is required' : true;
                            }
                        }
                    }}
                />
                <Input
                    control={control}
                    name={'zip'}
                    title={'Zip Code*'}
                    type={'zip'}
                    placeholder={'Enter Zip Code'}
                    inputProps={{maxLength: 5}}
                    rules={{
                        required: "This field is required",
                    }}
                />
            </div>
            <div className="formContainer__row">
                <Textarea
                    control={control}
                    name={'notes'}
                    title={'Customer Notes'}
                    placeholder={''}
                />
            </div>
            <Button
                onClick={handleSubmit((data) => handleOnSubmit(data))}
                variant="contained"
                className="simpleBtn"
            >
                Save
            </Button>
        </form>
    );
};

export default CustomerForm;

