import React, {FC, useEffect, useState, useCallback} from 'react';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import {useForm, useWatch} from "react-hook-form";
import moment from 'moment';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import {useAppDispatch, useAppSelector} from "../../../hooks/global";
import {fetchReferrals, fetchMakeReferralPaid, fetchReferralsReport} from "../../../store/affiliate/affiliateSlice";
import HeaderView from '../../common/Header/HeaderView';
import FooterView from '../../common/Footer/FooterView';
import TopBanner from '../../common/TopBanner/TopBanner';
import DataTableView from "../../common/DataTable/DataTableView";
import Spinner from "../../common/Spinner/Spinner";
import TableInfo from "../AffiliateReport/reportInfoTable/Table";
import {Select} from "../../controls";
import './AffiliateReport.scss';

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const columns = [
  {
    name: 'Transaction\nDate',
    alias: 'created_at',
    selector: (row: any) => row.created_at && moment(row.created_at).format('MM/DD/YYYY hh:mm A'),
    sortable: false,
    width: '120px',
  },
  {
    name: 'Customer\nName',
    alias: 'full_name',
    selector: (row: any) => row.user.profile.full_name,
    sortable: false,
  },
  {
    name: 'Company\nName',
    alias: 'company',
    selector: (row: any) => row.user.profile.company,
    sortable: false,
  },
  {
    name: 'Items\nPurchased',
    alias: 'package',
    selector: (row: any) => row.package_name,
    sortable: false,
  },
  {
    name: 'Transaction\nStatus',
    alias: 'status_title',
    selector: (row: any) => row.status_title,
    sortable: false,
    width: '120px',
  },
  {
    name: 'Order\nTotal',
    alias: 'price',
    selector: (row: any) => formatter.format(row.price),
    sortable: false,
    width: '100px',
  },
  {
    name: 'Commission\n(Percent or Flat Fee)',
    alias: 'affiliate_commission_amount',
    selector: (row: any) => formatter.format(row.affiliate_commission_amount),
    sortable: false,
  },
  {
    name: 'Paid',
    alias: 'affiliate_paid_at',
    selector: (row: any) => row.affiliate_paid_at && moment(row.affiliate_paid_at).format('MM/DD/YYYY hh:mm A'),
    sortable: false,
    width: '120px',
  },
];

const AffiliateReport: FC = () => {
  const ipp = 5;
  const {id} = useParams();
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);
  const {affiliate, referrals, isLoading} = useAppSelector(state => state.affiliate);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (id) dispatch(fetchReferrals({id, year: moment().format('YYYY')}));
  }, [id]);

  const getCustomerName = (affiliate: any): string => {
    return `${affiliate?.profile.first_name || ''} ${affiliate?.profile.last_name || ''}`
  }

  const {control} = useForm({
    mode: 'onBlur',
    defaultValues: {
      years: moment().format('YYYY'),
    }
  });

  const year = useWatch({
    control,
    name: 'years',
  });

  useEffect(() => {
    if (id && year) dispatch(fetchReferrals({id, year}));
  }, [year]);

  const getYears = () => {
    const now = Number(moment().format('YYYY'));
    const years = [];
    for (let i = 0; i < 10; i++) {
      years.push({value: (now-i).toString(), label: (now-i).toString()});
    }
    return years;
  }

  const rowSelected = (row: any) => row.affiliate_paid_at !== null;

  const handleRowSelected = useCallback((state: any) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleSave = () => {
    const items: Array<any> = [];
    const from = ipp*(currentPage-1);
    const to = ipp*currentPage-1;

    referrals.map((referral, idx) => {
      if (idx >= from && idx <= to) {
        items.push({
          id: referral.id,
          action: (selectedRows.find((item: any) => item.id === referral.id)) ? 'paid' : 'unpaid'
        });
      }
    });

    if (window.confirm('Are you sure you want to perform this action?')) {
      dispatch(fetchMakeReferralPaid({id, items: {items}}));
    }
  }

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
    setSelectedRows([]);
  }

  const handleReportExport = () => {
    dispatch(fetchReferralsReport({id, year}));
  }

  return (
    <div className="contentWrapper">
      <HeaderView />
      <Spinner isLoading={isLoading}/>
      <div className="pageContainer">
        <TopBanner />
        <div className="pageContainer__view">
          <div className="pageHeader">
            <div className="pageHeader__rowTitle">
              <ul className="pageHeader__tabList">
                <li className="pageHeader__tabItem">
                  <Link
                    underline="hover"
                    component={RouterLink}
                    className="pageHeader__tabLink"
                    to={`/affiliate/${id}`}
                  >
                    Channel Partner Details
                  </Link>
                </li>
                <li className="pageHeader__tabItem state_active">
                  <Link
                    underline="hover"
                    component={RouterLink}
                    className="pageHeader__tabLink"
                    to={''}
                  >
                    Channel Partner Referral Report
                  </Link>
                </li>
              </ul>
              <Button
                onClick={() => navigation('/affiliates', {state: {keepFilters: true}})}
                variant="contained"
                className="pageHeader__btn -desktop"
              >
                <span className="pageHeader__btnIcon -back"/>
                Back
              </Button>
            </div>
            <div className="pageHeader__rowSubTitle">
              <span>{getCustomerName(affiliate)}</span> | <span>{affiliate?.profile?.company || ''}</span>
            </div>
            <Button
              onClick={() => navigation('/affiliates', {state: {keepFilters: true}})}
              variant="contained"
              className="pageHeader__btn -mobile"
            >
              <span className="pageHeader__btnIcon -back"/>
              Back
            </Button>
          </div>
          <div className="pageReportInfo">
            <div className="pageReportInfo__header">
              <div className="pageReportInfo__selectYear">
                <Select
                    title={'Year'}
                    name={'years'}
                    options={getYears()}
                    placeholder={''}
                    control={control}
                />
              </div>
              <Button
                  onClick={handleReportExport}
                  variant="contained"
                  className="pageReportInfo__btn simpleBtn -border">
                Export to Excel
              </Button>
            </div>
          </div>
          {affiliate?.commissions && (
              <TableInfo affiliate={affiliate} />
          )}
          <div className="tableList -customer">
            <DataTableView
                data={referrals}
                columns={columns}
                pagination={true}
                selectableRows={true}
                onChangeSelected={handleRowSelected}
                clearSelectedRows={false}
                selectableRowSelected={rowSelected}
                paginationServer={false}
                paginationPerPage={ipp}
                onChangePage={handleChangePage}
            />
          </div>
          {!!referrals.length && (
              <Button
                  onClick={handleSave}
                  variant="contained"
                  className="pageReportInfo__btn simpleBtn">
                Save
              </Button>
          )}
        </div>
      </div>
      <FooterView />
    </div>
  );
};

export default AffiliateReport;
