import React, { FC, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FormHelperText } from '@mui/material';
import Link from '@mui/material/Link';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { ROUTES } from '../../../router/routes';
import { useAppDispatch, useAppSelector } from '../../../hooks/global';
import { fetchSignIn } from '../../../store/auth/authSlice';
import Spinner from '../../common/Spinner/Spinner';
import logoImg from '../../../assets/images/login_logo.png';
import { ReactComponent as UserIcon } from '../../../assets/images/icons/user-field_ic.svg';
import { ReactComponent as PasswordIcon } from '../../../assets/images/icons/password-field_ic.svg';
import { ReactComponent as CheckboxIcon } from '../../../assets/images/icons/checkbox_ic.svg';
import { ReactComponent as CheckboxCheckedIcon } from '../../../assets/images/icons/checkbox-checked_ic.svg';
import './SignIn.scss';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const SignIn: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoading } = useAppSelector(state => state.auth);
  const navigateToCustomers = () => {
    navigate('/customers');
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, touchedFields },
  } = useForm({
    mode: 'onBlur',
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = (data: any) => {
    dispatch(fetchSignIn(data)).then(resp => {
      if (resp?.type === 'auth/login/fulfilled') {
        reset();
        navigateToCustomers();
      }
    });
  };

  const submitButtonStatus = () => {
    return !(!Object.keys(touchedFields).length || isValid) || isLoading;
  };

  return (
    <div className="signInContainer">
      <div className="signInView">
        <img src={logoImg} width="267" height="100" alt="Logo" className="mainLogo" />
        <Spinner isLoading={isLoading} />
        <form
          className="authForm"
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <h1 className="authForm__title">Login</h1>
          <Input
            {...register('username', {
              required: 'This field is required',
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Invalid email address',
              },
            })}
            id={'username'}
            className="authField -username"
            placeholder="Username"
            error={!!errors.username}
            startAdornment={
              <InputAdornment position="start">
                <UserIcon color="primary" />
              </InputAdornment>
            }
          />
          {!!errors.username && (
            <FormHelperText error id="password">
              {`${errors.username.message}`}
            </FormHelperText>
          )}
          <Input
            {...register('password', {
              required: 'This field is required',
            })}
            className="authField -password"
            type={showPassword ? 'text' : 'password'}
            placeholder="Enter Password"
            error={!!errors.password}
            startAdornment={
              <InputAdornment position="start">
                <PasswordIcon color="primary" />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment
                position="end"
                className="password-eye"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <Visibility color="primary" />
                ) : (
                  <VisibilityOff color="primary" />
                )}
              </InputAdornment>
            }
          />
          {!!errors.password && (
            <FormHelperText error id="password">
              {`${errors.password.message}`}
            </FormHelperText>
          )}
          <div className="authForm__row">
            {/*<FormControlLabel className="authCheckbox" control={*/}
            {/*  <Checkbox*/}
            {/*      icon={<CheckboxIcon />}*/}
            {/*      checkedIcon={<CheckboxCheckedIcon />}*/}
            {/*      defaultChecked />*/}
            {/*} label="Remember Me" />*/}
            <Link
              id={'ForgotPassword'}
              component={RouterLink}
              underline="hover"
              className="authForm__link"
              to={ROUTES.FORGOT_PASSWORD}
            >
              Forgot Password?
            </Link>
          </div>
          <Button
            id={'LogIn'}
            onClick={handleSubmit(handleLogin)}
            variant="contained"
            disabled={submitButtonStatus()}
            className="authForm__btn"
          >
            log in
          </Button>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
