import React, { FC } from 'react';
import './TopBanner.scss'

const TopBanner: FC = () => {

  return (
      <div className="topBanner">
        <img
            className="topBanner__img -desktop"    
            src={require('../../../assets/images/topBanner_bg.jpg')} 
            alt="Banner image"/>
        <img
            className="topBanner__img -mobile"
            src={require('../../../assets/images/topBanner-mobile_bg.jpg')}
            alt="Banner image"/>
      </div>
  );
};

export default TopBanner;
