import React, {FC} from 'react';
import {Controller} from 'react-hook-form';
import {FormHelperText, Select as MUISelect} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import './Select.scss'

interface Props {
    name?: string;
    title: string;
    options?: Array<{ value: string, label: string }>;
    control?: any;
    selectOne?: string;
    placeholder?: string;
    rules?: Record<string, unknown>;
    showErrorMessage?: boolean;
    className?: string;
}

const Select: FC<Props> = ({
       name,
       title,
       options = [{value: '', label: 'Select One'}],
       control,
       placeholder = '',
       rules = {},
       showErrorMessage = true,
       className = '',
    }) => {

    return (
        <div className={className}>
            <Controller
                name={name || ''}
                control={control}
                rules={rules}
                render={({field: {onChange, value}, fieldState: {error}}) =>
                    <FormControl className="selectField">
                        <div className="selectField__title">{title}</div>
                        <MUISelect
                            className={`selectField__select ${error ? 'error' : ''}`}
                            displayEmpty
                            value={value || ''}
                            onChange={onChange}
                            error={!!error}
                        >
                            {!!placeholder && (
                                <MenuItem key={''} value={''}>
                                    {placeholder}
                                </MenuItem>
                            )}
                            {options.map((item: any, idx: number) => (
                                <MenuItem key={idx} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </MUISelect>
                        {showErrorMessage && !!error && (
                            <FormHelperText error id={name}>
                                {`${error.message}`}
                            </FormHelperText>
                        )}
                    </FormControl>
                }
            />
        </div>
    );
};

export default Select;
