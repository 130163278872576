import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from '../../service/api';
import {Customer} from "../../constants";

export const fetchCustomers = createAsyncThunk('customer/list', async (params: any) => {
    const response = await api.get('admin/customers/list', { params });
    return response.data;
});

export const fetchCustomer = createAsyncThunk('customer/get', async (id: string) => {
    const response = await api.get(`admin/customers/${id}/get`);
    return response.data.resource;
});

export const fetchUpdateCustomer = createAsyncThunk('customer/update', async (params: any, {
    rejectWithValue,
    dispatch
}) => {
    const {id, data} = params;
    const response = await api.post(`admin/customers/${id}/update`, convertDataToServer(data));
    return response.data?.resource;
});

export const fetchCreateCustomer = createAsyncThunk('customer/create', async (params: any, {
    rejectWithValue,
    dispatch
}) => {
    const response = await api.post('admin/customers/create', convertDataToServer(params));
    return response.data?.resource;
});

interface  ListFilters {
    order_by: string,
    order_dir: string,
    page: number,
    ipp: number,
    first_name: string,
    last_name: string,
    company: string,
    phone: string,
    email: string,
    state_id: string,
    is_active: number,
}

interface  Pagination {
    total: number,
    current_page: number,
    last_page: number,
    per_page: number,
    from: number,
    to: number,
}

interface CustomerState {
    customers: Array<object>;
    customer: any;
    orders: Array<object>;
    listFilters: ListFilters;
    pagination: Pagination;
    isLoading: boolean;
}

const initialState: CustomerState = {
    customers: [],
    orders: [],
    customer: null,
    listFilters: {
        order_by: 'first_name',
        order_dir: 'asc',
        page: 1,
        ipp: 25,
        first_name: '',
        last_name: '',
        company: '',
        phone: '',
        email: '',
        state_id: '',
        is_active: 1,
    },
    pagination: {
        total: 0,
        current_page: 1,
        last_page: 1,
        per_page: 1,
        from: 1,
        to: 1,
    },
    isLoading: false,
};

export const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        setFilterParams: (state, action) => {
            state.listFilters = action.payload;
        },
        resetFilters: (state) => {
            state.listFilters = initialState.listFilters;
        },
        setCustomer: (state, action) => {
            state.customer = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchCustomers.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchCustomers.fulfilled, (state: any, action: any) => {
                state.customers = action.payload.resource.data;
                state.pagination = {
                    ...state.pagination,
                    current_page: action.payload.resource.current_page,
                    total: action.payload.resource.total,
                    last_page: action.payload.resource.last_page,
                    ipp: action.payload.resource.ipp,
                    page: action.payload.resource.current_page,
                    per_page: action.payload.resource.per_page,
                    from: action.payload.resource.from,
                    to: action.payload.resource.to,
                }
                state.isLoading = false;
            })
            .addCase(fetchCustomers.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchCustomer.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchCustomer.fulfilled, (state: any, action: any) => {
                state.customer = action.payload;
                state.orders = action.payload?.orders || [];
                state.isLoading = false;
            })
            .addCase(fetchCustomer.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchUpdateCustomer.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUpdateCustomer.fulfilled, (state: any, action: any) => {
                state.customer = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchUpdateCustomer.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchCreateCustomer.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchCreateCustomer.fulfilled, (state: any, action: any) => {
                state.customer = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchCreateCustomer.rejected, state => {
                state.isLoading = false;
            })
            .addDefaultCase(() => {
            });
    },
});

const convertDataToServer = (data: any) => {
    const ret: Customer = {
        user: {
            email: data.email,
            is_active: data.is_active,
            ...(data.new_pwd && {password: data.new_pwd})
        },
        profile: {
            first_name: data.first_name,
            last_name: data.last_name,
            company: data.company,
            notes: data.notes,
            warning_notes: data.warning_notes,
            affiliate_id: (parseInt(data.affiliate_id) > 0) ? parseInt(data.affiliate_id) : null,
            referred_type: data.referred_type,
            referred_value: data.referred_value,
        },
        address: {
            state_id: data.state_id,
            address: data.address,
            address2: data.address2,
            city: data.city,
            phone: data.phone,
            zip: data.zip,
        },
    };

    return ret;
}

export const { setFilterParams, resetFilters, setCustomer } = customerSlice.actions;

export default customerSlice.reducer;
