import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from '../../service/api';

export const fetchUsers = createAsyncThunk('users/list', async (params: any) => {
    const response = await api.get('admin/users/list', { params });
    return response.data;
});

export const fetchUserById = createAsyncThunk('users/get', async (id: string) => {
    const response = await api.get(`admin/users/${id}/get`);
    return response.data.resource;
});

export const fetchUpdateUser = createAsyncThunk('users/update', async (params: any, {
    rejectWithValue,
    dispatch
}) => {
    const {id, data} = params;
    const response = await api.post(`admin/users/${id}/update`, convertDataToServer(data));
    return response.data?.resource;
});

export const fetchUserCreate = createAsyncThunk('users/create', async (params: any, {
    rejectWithValue,
    dispatch
}) => {
    const response = await api.post('admin/users/create', convertDataToServer(params));
    return response.data?.resource;
});

interface  ListFilters {
    order_by: string,
    order_dir: string,
    page: number,
    ipp: number,
    first_name: string,
    last_name: string,
    role: string,
    email: string,
    is_active: number,
}

interface  Pagination {
    total: number,
    current_page: number,
    last_page: number,
    per_page: number,
    from: number,
    to: number,
}

interface UserState {
    users: Array<object>;
    user: object | null;
    listFilters: ListFilters;
    pagination: Pagination;
    isLoading: boolean;
}

const initialState: UserState = {
    users: [],
    user: null,
    listFilters: {
        order_by: 'first_name',
        order_dir: 'asc',
        page: 1,
        ipp: 25,
        first_name: '',
        last_name: '',
        role: '',
        email: '',
        is_active: 1,
    },
    pagination: {
        total: 0,
        current_page: 1,
        last_page: 1,
        per_page: 1,
        from: 1,
        to: 1,
    },
    isLoading: false,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setFilterParams: (state, action) => {
            state.listFilters = action.payload;
        },
        resetFilters: (state) => {
            state.listFilters = initialState.listFilters;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchUsers.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUsers.fulfilled, (state: any, action: any) => {
                state.users = action.payload.resource.data;
                state.pagination = {
                    ...state.pagination,
                    current_page: action.payload.resource.current_page,
                    total: action.payload.resource.total,
                    last_page: action.payload.resource.last_page,
                    ipp: action.payload.resource.ipp,
                    page: action.payload.resource.current_page,
                    per_page: action.payload.resource.per_page,
                    from: action.payload.resource.from,
                    to: action.payload.resource.to,
                }
                state.isLoading = false;
            })
            .addCase(fetchUsers.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchUserById.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUserById.fulfilled, (state: any, action: any) => {
                state.user = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchUserById.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchUpdateUser.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUpdateUser.fulfilled, (state: any, action: any) => {
                state.user = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchUpdateUser.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchUserCreate.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUserCreate.fulfilled, (state: any, action: any) => {
                state.user = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchUserCreate.rejected, state => {
                state.isLoading = false;
            })
            .addDefaultCase(() => {
            });
    },
});

const convertDataToServer = (data: any) => {
    const ret: any = {
        user: {
            email: data.email,
            role: data.role,
            is_active: data.is_active,
            ...(data.password && {password: data.password})
        },
        profile: {
            first_name: data.first_name,
            last_name: data.last_name,
        }
    };

    return ret;
}

export const { setFilterParams, resetFilters, setUser } = userSlice.actions;

export default userSlice.reducer;
