import React, {FC} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import {useAppDispatch, useAppSelector} from "../../../hooks/global";
import {fetchUserCreate} from "../../../store/user/userSlice";
import HeaderView from '../../common/Header/HeaderView';
import FooterView from '../../common/Footer/FooterView';
import TopBanner from '../../common/TopBanner/TopBanner';
import {UserForm} from "../../common";
import Spinner from "../../common/Spinner/Spinner";

const UserCreate: FC = () => {
    const navigation = useNavigate();
    const dispatch = useAppDispatch();
    const {isLoading} = useAppSelector(state => state.user);

    const handleSubmit = (data: any) => {
        dispatch(fetchUserCreate(data)).then(resp => {
            if (resp?.type === 'users/create/fulfilled') {
                navigation('/users', {state: {keepFilters: true}});
            }
        });
    };

    return (
        <div className="contentWrapper">
            <HeaderView/>
            <Spinner isLoading={isLoading}/>
            <div className="pageContainer">
                <TopBanner/>
                <div className="pageContainer__view">
                    <div className="pageHeader">
                        <div className="pageHeader__rowTitle">
                            <ul className="pageHeader__tabList">
                                <li className="pageHeader__tabItem state_active">
                                    <Link
                                        underline="hover"
                                        component={RouterLink}
                                        className="pageHeader__tabLink"
                                        to={''}
                                    >
                                        Add New User
                                    </Link>
                                </li>
                            </ul>
                            <Button
                                onClick={() => navigation('/users', {state: {keepFilters: true}})}
                                variant="contained"
                                className="pageHeader__btn -desktop"
                            >
                                <span className="pageHeader__btnIcon -back"></span>
                                Back
                            </Button>
                        </div>
                        <Button
                            onClick={() => navigation('/users', {state: {keepFilters: true}})}
                            variant="contained"
                            className="pageHeader__btn -mobile"
                        >
                            <span className="pageHeader__btnIcon -back"></span>
                            Back
                        </Button>
                    </div>
                    <UserForm
                        handleOnSubmit={handleSubmit}
                        handleActive={()=>{}}
                    />
                </div>
            </div>
            <FooterView/>
        </div>
    );
};

export default UserCreate;
