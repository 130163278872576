import React, {FC} from 'react';
import {Pagination as MUIPagination} from '@mui/material';
import {PaginationProps} from '../../../constants';
import './Pagination.scss';

function Pagination(props: PaginationProps) {
    const {
        rowCount,
        currentPage,
        lastPage,
        from,
        to,
        onChangePage,
        infinityPagination,
    } = props;

    return (
        <div className="paginationTable">
            <div className="paginationTable__countPerPage">
                Displaying {from} to {to} of {rowCount} entries
            </div>
            <MUIPagination
                count={lastPage}
                page={currentPage}
                onChange={(event, page) => {
                    onChangePage(page)
                }}
                // showFirstButton
                // showLastButton
            />
        </div>
    );
}

export default Pagination;
