import React, {FC, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate, useSearchParams} from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import {FormHelperText} from "@mui/material";
import {useAppDispatch, useAppSelector} from '../../../hooks/global';
import {fetchSetPassword} from "../../../store/auth/authSlice";
import Spinner from "../../common/Spinner/Spinner";
import logoImg from '../../../assets/images/login_logo.png';
import {ReactComponent as PasswordIcon} from "../../../assets/images/icons/password-field_ic.svg";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import './SetPassword.scss'

const passwordRules = {
    pattern: {
        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
        message: 'Minimum 8 characters, at least one uppercase and lowercase letter and one number',
    },
    required: "This field is required",
}

const SetPassword: FC = () => {
    const navigation = useNavigate();
    const dispatch = useAppDispatch();
    const {isLoading} = useAppSelector(state => state.auth);
    const [searchParams, setSearchParams] = useSearchParams();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const token = searchParams.get('token');

    const {
        register,
        handleSubmit,
        reset,
        getValues,
        formState: {errors, isValid, touchedFields},
    } = useForm({
        mode: 'onBlur'
    });
    const handleSetPassword = (data: any) => {
        dispatch(fetchSetPassword({...data, token})).then(resp => {
            if (resp?.type === 'auth/set_password/fulfilled') {
                reset();
                navigation('/sign-in');
            }
        });
    }

    const submitButtonStatus = () => {
        return !(!Object.keys(touchedFields).length || isValid) || isLoading;
    }

    return (
        <div className="forgotPasswordContainer">

            <div className="forgotPasswordView">
                <img
                    src={logoImg}
                    width="267"
                    height="100"
                    alt="Logo"
                    className="mainLogo"/>
                <Spinner isLoading={isLoading}/>
                <form className="authForm" onSubmit={e => {
                    e.preventDefault()
                }}>
                    <h1 className="authForm__title">Set Your Password</h1>
                    <p className="authForm__text">
                        To reset your password, please enter the email address you used when
                        creating your account. We will send an email to this account with
                        instructions to reset your password.
                    </p>
                    <Input
                        {...register('password', passwordRules)}
                        id={'password'}
                        className="authField -password"
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="new-password"
                        placeholder="Enter Password"
                        inputProps={{maxLength: 32}}
                        error={!!errors.password}
                        startAdornment={
                            <InputAdornment position="start">
                                <PasswordIcon color="primary"/>
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment
                                position="end"
                                className="password-eye"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? (
                                    <Visibility color="primary" />
                                ) : (
                                    <VisibilityOff color="primary" />
                                )}
                            </InputAdornment>
                        }
                    />
                    {!!errors.password && (
                        <FormHelperText error id="password">
                            {`${errors.password.message}`}
                        </FormHelperText>
                    )}
                    <Input
                        {...register('password_confirm', {
                            required: "This field is required",
                            validate: (value) => {
                                return value === getValues('password') ? true : 'Passwords should be the same';
                            }
                        })}
                        id={'password_confirm'}
                        className="authField -password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        autoComplete="new-password"
                        placeholder="Confirm Password"
                        inputProps={{maxLength: 32}}
                        error={!!errors.password_confirm}
                        startAdornment={
                            <InputAdornment position="start">
                                <PasswordIcon color="primary"/>
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment
                                position="end"
                                className="password-eye"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            >
                                {showConfirmPassword ? (
                                    <Visibility color="primary" />
                                ) : (
                                    <VisibilityOff color="primary" />
                                )}
                            </InputAdornment>
                        }
                    />
                    {!!errors.password_confirm && (
                        <FormHelperText error id="password_confirm">
                            {`${errors.password_confirm.message}`}
                        </FormHelperText>
                    )}
                    <Button
                        onClick={handleSubmit(handleSetPassword)}
                        disabled={submitButtonStatus()}
                        variant="contained"
                        className="authForm__btn">
                        Set Password
                    </Button>
                    <Link
                        underline="none"
                        className="authForm__linkBack"
                        onClick={() => navigation('/sign-in')}
                    >
                        Back to Login
                    </Link>
                </form>
            </div>
        </div>
    );
};

export default SetPassword;
