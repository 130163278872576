import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate } from 'react-router';
import {useAppSelector} from "../hooks/global";
import { ROUTES } from './routes';
import { ThemeProvider } from '@mui/material';
import { theme } from '../assets/styles/themes';
import {
  SignIn,
  ForgotPassword,
  Customers,
  CustomerCreate,
  CustomerEdit,
  CustomersHistory,
  Affiliates,
  AffiliateCreate,
  AffiliateEdit,
  AffiliateReport,
  SetPassword,
  Users,
  UserCreate,
  UserEdit,
  QuestionsList,
  QuestionEdit,
  DocumentsList,
  DocumentEdit,
  AffiliateRequests,
  NotFound,
} from '../components/pages';
import PagesList from "../components/pages/Pages/PagesList";
import CategoriesList from "../components/pages/Categories/CategoriesList";
import Page from "../components/pages/Page/Page";
import Category from "../components/pages/Category/Category";

const Router = () => {
  const {accessToken, role} = useAppSelector(state => state.auth);
  const isAuthenticated  = !!localStorage.getItem('access_token') || !!accessToken;
  const userRole  = localStorage.getItem('role') || role;

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path={ROUTES.SIGN_IN} element={<SignIn />} />
        <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={ROUTES.SET_PASSWORD} element={<SetPassword />} />
        {isAuthenticated && (
            <>
              <Route path="/" element={<Navigate to={ROUTES.CUSTOMERS} />} />
              <Route path={ROUTES.CUSTOMERS} element={<Customers />} />
              <Route path={ROUTES.CUSTOMER_CREATE} element={<CustomerCreate />} />
              <Route path={ROUTES.CUSTOMER_EDIT} element={<CustomerEdit />} />
              <Route path={ROUTES.CUSTOMER_HISTORY} element={<CustomersHistory />} />
              <Route path={ROUTES.AFFILIATES} element={<Affiliates />} />
              <Route path={ROUTES.AFFILIATE_CREATE} element={<AffiliateCreate />} />
              <Route path={ROUTES.AFFILIATE_EDIT} element={<AffiliateEdit />} />
              <Route path={ROUTES.AFFILIATE_REPORTS} element={<AffiliateReport />} />
              <Route path={ROUTES.AFFILIATE_REQUESTS} element={<AffiliateRequests />} />
              {userRole === 'admin' && (
                  <>
                    <Route path={ROUTES.USERS} element={<Users />} />
                    <Route path={ROUTES.USER_CREATE} element={<UserCreate />} />
                    <Route path={ROUTES.USER_EDIT} element={<UserEdit />} />
                    <Route path={ROUTES.QUESTIONS} element={<QuestionsList />} />
                    <Route path={ROUTES.QUESTION_EDIT} element={<QuestionEdit />} />
                    <Route path={ROUTES.DOCUMENTS} element={<DocumentsList />} />
                    <Route path={ROUTES.DOCUMENT_EDIT} element={<DocumentEdit />} />
                    <Route path={ROUTES.CATEGORIES} element={<CategoriesList />} />
                    <Route path={ROUTES.CATEGORY_CREATE} element={<Category />} />
                    <Route path={ROUTES.CATEGORY_EDIT} element={<Category />} />
                    <Route path={ROUTES.PAGES} element={<PagesList />} />
                    <Route path={ROUTES.PAGE_CREATE} element={<Page />} />
                    <Route path={ROUTES.PAGE_EDIT} element={<Page />} />
                  </>
              )}
              <Route path="*" element={<NotFound/>} />
            </>
        )}
        <Route path="*" element={<Navigate to="/sign-in" replace />} />
      </Routes>
    </ThemeProvider>
  );
};

export default Router;
