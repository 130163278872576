import React, { FC } from 'react';
import './FooterView.scss'
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTES } from '../../../router/routes';
import footerLogoImg from '../../../assets/images/auth-logo-white.svg'

const FooterView: FC = () => {

  return (
      <div className="footerContainer">
        <div className="footerView">
          <div className="footerLogo">
            <img 
                className="footerLogo__img" 
                src={footerLogoImg} 
                alt="Logo"/>
          </div>
        </div>
      </div>
  );
};

export default FooterView;
