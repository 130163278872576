import React, {FC, useEffect, useState} from 'react';
import {useForm, useWatch} from "react-hook-form";
import Button from '@mui/material/Button';
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import {fetchUser} from "../../../store/auth/authSlice";
import {Input} from "../../controls";
import './UserForm.scss';
import {useAppDispatch, useAppSelector} from "../../../hooks/global";

type Props = {
    user?: any
    handleOnSubmit: (data: any) => void
    handleActive: (isActive: boolean) => void
};

const passwordRules = {
    pattern: {
        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
        message: 'Minimum 8 characters, at least one uppercase and lowercase letter and one number',
    },
    required: "This field is required",
}

const UserForm: FC<Props> = ({
     user,
     handleOnSubmit,
     handleActive
 }) => {
    const dispatch = useAppDispatch();
    const authUser = useAppSelector(state => state.auth.user);
    const [role, setRole] = useState('admin');
    const {control, handleSubmit, reset, getValues, setValue,  formState: {errors}} = useForm({
        mode: 'onBlur',
        defaultValues: {
            email: '',
            is_active: '1',
            first_name: '',
            last_name: '',
            role: '',
            password: '',
        }
    });

    const password = useWatch({
        control,
        name: 'password',
    });

    useEffect(() => {
        dispatch(fetchUser());
    }, []);

    useEffect(() => {
        reset({
            email: user?.email || '',
            is_active: user ? (user?.is_active ? '1' : '0') : '1',
            first_name: user?.profile.first_name || '',
            last_name: user?.profile.last_name || '',
            role: user?.role.alias || 'admin',
            password: '',
        });
        setRole(user?.role.alias || 'admin');
    }, [user]);

    const handleRole = (value: string) => {
        setValue('role', value);
        setRole(value);
    }

    return (
        <form className="formContainer formContainer_createUser">
            <div className="formBlock formBlock_permissions">
                <h3 className="formBlock__title">Permissions</h3>

                <div className={"formBlockInline"}>

                    <RadioGroup
                        row
                        aria-labelledby="row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                    >
                        <FormControlLabel
                            value="admin"
                            control={
                                <Radio checked={role === 'admin'} onClick={() => handleRole('admin')} />
                            }
                            label={
                                <>
                                    Super Administrator
                                    <span className="radioRestLabel">Access to full system Customers,</span><span className="radioRestLabel">Affiliates, and User Management.</span>
                                </>
                            }
                        />
                        <FormControlLabel
                            value="user"
                            control={
                                <Radio checked={role === 'staff'} onClick={() => handleRole('staff')} />
                            }
                            label={
                                <>
                                    Staff User
                                    <span className="radioRestLabel">Access to Customers </span><span className="radioRestLabel">and Affiliates.</span>
                                </>
                            }
                        />
                    </RadioGroup>
                    {(user?.id && authUser?.id !== user.id) && (
                        <div className={"activeBtnWrap"}>
                            <Button
                                onClick={() => {handleActive(user.is_active)}}
                                variant="contained"
                                className="simpleBtn activeBtn"
                            >
                                Mark {user.is_active ? 'Inactive' : 'Active'}
                            </Button>
                        </div>
                    )}
                </div>
            </div>

            <div className="formBlock">
                <h3 className="formBlock__title">Personal Information</h3>
                <Input
                    control={control}
                    name={'first_name'}
                    title={'First Name*'}
                    placeholder={'Enter First Name'}
                    rules={{
                        required: "This field is required",
                    }}
                    inputProps={{maxLength: 50}}
                />
                <Input
                    control={control}
                    name={'last_name'}
                    title={'Last Name*'}
                    placeholder={'Enter Last Name'}
                    rules={{
                        required: "This field is required",
                    }}
                    inputProps={{maxLength: 50}}
                />
            </div>

            <div className="formBlock">
                <h3 className="formBlock__title">Account Information</h3>
                <Input
                    control={control}
                    name={'email'}
                    title={'Email Address*'}
                    placeholder={'Enter Email Address'}
                    rules={{
                        required: "This field is required",
                        pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Invalid Email Address"
                        }
                    }}
                    inputProps={{maxLength: 100}}
                />
                <Input
                    control={control}
                    name={'password'}
                    title={`Password${user?.id ? '' : '*'}`}
                    type={'password'}
                    placeholder={'Enter password'}
                    //rules={user?.id ? {} : {required: "This field is required"}}
                    rules={(!user?.id || !!password) ? passwordRules : {} }
                    // rules={{...passwordRules}}
                    inputProps={{maxLength: 50}}
                />
            </div>

            <div className="userManagementBtnsContainer btnsWrapper">
                <>
                    <Button
                        onClick={handleSubmit((data) => handleOnSubmit(data))}
                        variant="contained"
                        className="simpleBtn"
                    >
                        Save
                    </Button>
                </>
            </div>
        </form>
    );
};

export default UserForm;

