import {configureStore} from '@reduxjs/toolkit';
import generalReducer from './general/generalSlice';
import authReducer from './auth/authSlice';
import userReducer from './user/userSlice';
import customerReducer from './customer/customerSlice';
import affiliateReducer from './affiliate/affiliateSlice';
import questionReducer from './question/questionSlice';
import documentReducer from './document/documentSlice';
import categorySlice from './category/categorySlice';
import pageReducer from './page/pageSlice';

export const store = configureStore({
    reducer: {
        general: generalReducer,
        auth: authReducer,
        user: userReducer,
        customer: customerReducer,
        affiliate: affiliateReducer,
        question: questionReducer,
        document: documentReducer,
        category: categorySlice,
        page: pageReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
