import React, {FC, useEffect, useRef, useState} from 'react';
import {useForm, useWatch} from "react-hook-form";
import {Input, Select, Textarea, TinyMceEditor} from "../../controls";
import RadioGroup from "@mui/material/RadioGroup";
import Button from '@mui/material/Button';
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import './DocumentForm.scss';

type Props = {
    document?: any
    handleOnSubmit: (data: any) => void
};

const statusOptions = [
    {value: '1', label: 'Active'},
    {value: '0', label: 'Archived'},
]

const DocumentForm: FC<Props> = ({
     document,
     handleOnSubmit
 }) => {
    const editorRef = useRef(null);
    const {control, handleSubmit, reset, setValue, getValues, formState: {errors}} = useForm({
        mode: 'onBlur',
        defaultValues: {
            alias: '',
            title: '',
            content: '',
            is_active: '1',
        }
    });

    useEffect(() => {
        reset({
            alias: document?.alias || '',
            title: document?.title || '',
            //is_active: document ? (document?.is_active ? '1' : '0') : '1',
            content: document?.content || '',
        });
    }, [document]);

    const handleChangeEditor = () => {
        if (editorRef.current) {
            // @ts-ignore
            setValue('content', editorRef.current?.getContent());
        }
    }

    const content = useWatch({
        control,
        name: 'content',
    });

    return (
        <form className="formContainer">
            <div className="formContainer__row -grid-2">
                <Input
                    control={control}
                    name={'title'}
                    title={'Title'}
                    placeholder={'Title'}
                    rules={{
                        required: "This field is required",
                    }}
                    inputProps={{maxLength: 50}}
                />
            </div>
            <div className="formContainer__row -grid-2">
                <Input
                    control={control}
                    name={'alias'}
                    title={'Alias'}
                    placeholder={'Alias'}
                    rules={{
                        required: "This field is required",
                    }}
                    inputProps={{maxLength: 50}}
                />
            </div>
            <div className="formContainer__row -grid-1">
                <TinyMceEditor
                    editorRef={editorRef}
                    value={content}
                    onChange={handleChangeEditor}
                />
            </div>
            <Button
                onClick={handleSubmit((data) => handleOnSubmit(data))}
                variant="contained"
                className="simpleBtn"
            >
                Save
            </Button>
        </form>
    );
};

export default DocumentForm;

