import React, {FC, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import Link from '@mui/material/Link';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import EmailIcon from '@mui/icons-material/Email';
import {FormHelperText} from "@mui/material";
import {useAppDispatch, useAppSelector} from '../../../hooks/global';
import {fetchForgotPassword, fetchSignIn} from "../../../store/auth/authSlice";
import Spinner from "../../common/Spinner/Spinner";
import logoImg from '../../../assets/images/login_logo.png';
import './ForgotPassword.scss'

const ForgotPassword: FC = () => {
    const navigation = useNavigate();
    const dispatch = useAppDispatch();
    const [isSendConfirmed, setSendConfirmed] = useState<boolean>(false);
    const {isLoading} = useAppSelector(state => state.auth);

    const {
        register,
        handleSubmit,
        reset,
        formState: {errors, isValid, touchedFields},
    } = useForm({
        mode: 'onBlur'
    });

    const handleForgotPassword = (data: any) => {
        dispatch(fetchForgotPassword(data)).then(resp => {
            if (resp?.type === 'auth/forgot_password/fulfilled') {
                reset();
                setSendConfirmed(true);
            }
        });
    }

    const submitButtonStatus = () => {
        return !(!Object.keys(touchedFields).length || isValid) || isLoading;
    }

    return (
        <div className="forgotPasswordContainer">

            <div className="forgotPasswordView forgotPasswordWrapper">
                <img
                    src={logoImg}
                    width="267"
                    height="100"
                    alt="Logo"
                    className="mainLogo"/>
                <Spinner isLoading={isLoading}/>
                <form className="authForm" onSubmit={e => {
                    e.preventDefault()
                }}>
                    <h1 className="authForm__title">Forgot Password</h1>

                    {isSendConfirmed ? (
                        <p className="authForm__text">
                            We sent you an email with the instructions to reset your password.
                        </p>
                    ) : (
                        <>
                            <p className="authForm__text">
                                To reset your password, please enter the email address you used when
                                creating your account. We will send an email to this account with
                                instructions to reset your password.
                            </p>
                            <Input
                                {...register('username', {
                                    required: "This field is required",
                                    pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: "Invalid email address"
                                    }
                                })}
                                id={'username'}
                                className="authField -email"
                                placeholder="Email"
                                inputProps={{maxLength: 50}}
                                error={!!errors.username}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <EmailIcon color="primary"/>
                                    </InputAdornment>
                                }
                            />
                            {!!errors.username && (
                                <FormHelperText error id="password">
                                    {`${errors.username.message}`}
                                </FormHelperText>
                            )}
                            <Button
                                id={'SendResentLink'}
                                onClick={handleSubmit(handleForgotPassword)}
                                disabled={submitButtonStatus()}
                                variant="contained"
                                className="authForm__btn">
                                Send Reset Link
                            </Button>
                        </>
                    )}
                    <Link
                        id={'BackToLogIn'}
                        underline="none"
                        className="authForm__linkBack"
                        onClick={() => navigation('/sign-in')}
                    >
                        Back to Login
                    </Link>
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;
